<template>
  <BaseDialogue
    class="board-form-dialogue"
    :class="[
      { 'board-form-dialogue__member': step === STEP_MEMBERS },
      $attrs.class
    ]"
    size="small"
    :title="title"
    :showOk="step !== STEP_MEMBERS"
    :showCancel="showCancel"
    :disableOk="!isValid"
    @ok="ok()"
    @cancel="back(true)"
  >
    <template #ok>{{ okButtonText }}</template>

    <transition :name="transitionName" @afterEnter="focus">
      <div
        key="base"
        v-if="step === 'base'"
        class="form-element board-form-dialogue__base"
        @keydown.enter="ok"
        tabindex="0"
      >
        <BorderField>
          <BaseInput
            ref="name"
            class="label-bold"
            :label="$t('boardForm.name')"
            name="name"
            :maxlength="105"
            v-model.trim="form.name"
          />
        </BorderField>

        <BorderField v-if="canSetLogo">
          <div class="board-form-dialogue__logo">
            <div v-if="preview" class="board-form-dialogue__logo--display">
              <div
                class="board-form-dialogue__logo--image"
                :style="previewStyle"
              ></div>
              <button
                class="button icon-button board-form-dialogue__logo--trash"
                @click="deleteLogo"
              >
                <TrashIcon />
              </button>
            </div>
            <div v-else-if="uploadInProgress">
              {{ $t('boardForm.uploadInProgress') }}
            </div>
            <FileInput
              v-else
              :accept="acceptedTypes"
              @change="fileSelected"
              name="files"
            >
              {{ $t('boardForm.selectLogo') }}
            </FileInput>
          </div>
        </BorderField>

        <BorderField v-if="mode === 'edit'">
          <SliderFieldset
            class="label-bold"
            :label="$t('boardForm.members.label')"
            :active="item.memberCount > 0"
            @v-click="displayMembers"
          />
        </BorderField>

        <BorderField>
          <SliderContent
            :label-bold="true"
            v-model="optionsExpanded"
            :label="$t('boardForm.options')"
          >
            <div class="form-field">
              <BaseCheckbox
                class="form-field-private"
                name="private"
                :label="$t('boardForm.private')"
                v-model="form.private"
                @update:modelValue="privateChanged"
              />
            </div>
            <div class="form-field">
              <BaseCheckbox
                class="form-field-protected"
                name="protected"
                :label="$t('boardForm.protected')"
                v-model="form.protected"
              />
            </div>
            <div class="form-field">
              <BaseCheckbox
                class="form-field-sharing-allowed"
                name="sharing-allowed"
                :label="$t('boardForm.sharingAllowed')"
                v-model="form.sharingAllowed"
                :disabled="form.private"
                @update:modelValue="sharingChanged"
              />
            </div>
            <div class="form-field">
              <BaseCheckbox
                class="form-field-copy-allowed"
                name="'copy-allowed'"
                :label="$t('boardForm.copyAllowed')"
                v-model="form.copyAllowed"
                :disabled="!form.sharingAllowed || form.private"
              />
            </div>
            <div class="form-field">
              <BaseCheckbox
                name="'send-all-add-notifications'"
                :label="$t('boardForm.sendAllAddNotifications')"
                v-model="form.sendAllAddNotifications"
              />
            </div>
          </SliderContent>
        </BorderField>
      </div>

      <MemberManagement
        :key="STEP_MEMBERS"
        v-else-if="step === STEP_MEMBERS"
        @countChanged="memberCountChanged"
        @ok="ok()"
        @cancel="back(true)"
        :parent="item"
        :type="item.type"
        :showPrevious="!inviteParticipants"
      />
    </transition>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useBoardStore } from '@/stores/board';
import '../../assets/stylesheets/components/_form.scss';
import { diff } from '../../utils/object';
import BaseDialogue from '../dialogues/BaseDialogue';
import FileInput from '../forms/FileInput';
import MemberManagement from '../member/MemberManagement';
import SliderFieldset from '../forms/SliderFieldset';
import SliderContent from '../forms/SliderContent';
import BorderField from '../forms/BorderField';
import ToastAlert from '../toast/ToastAlert';
import TrashIcon from '../icons/Trash';
import authMixins from '../mixins/auth';
import { toMegabytes } from '../../utils/numbers';

const acceptedTypes = ['.png', '.jpg', '.jpeg'];
const maxSize = 1024 * 1024 * 10; // 10MB

const STEP_BASE = 'base';
const STEP_MEMBERS = 'memberManagement';

export default {
  inheritAttrs: false,
  name: 'BoardFormDialogue',
  mixins: [authMixins],
  components: {
    BaseDialogue,
    FileInput,
    MemberManagement,
    SliderFieldset,
    SliderContent,
    ToastAlert,
    TrashIcon,
    BorderField
  },
  props: {
    editBoard: {
      type: Object,
      default: () => ({})
    },
    inviteParticipants: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      acceptedTypes,
      form: { file: null },
      item: { ...this.editBoard },
      optionsExpanded: false,
      selectedParticipant: null,
      preview: '',
      submitted: false,
      step: this.inviteParticipants ? STEP_MEMBERS : STEP_BASE,
      transitionName: 'prev',
      maxFileSize: toMegabytes(maxSize),
      isCreationProcess: !this.editBoard.id,
      error: ''
    };
  },
  computed: {
    ...mapState(useAppStore, ['upload']),
    STEP_MEMBERS() {
      return STEP_MEMBERS;
    },
    mode() {
      return this.item.id ? 'edit' : 'add';
    },
    isValid() {
      return !!this.form.name;
    },
    title() {
      let title = '';
      switch (this.step) {
        case STEP_BASE:
          title = `boardForm.base.label.${this.mode}`;
          break;
        case STEP_MEMBERS:
          title = 'boardForm.members.label';
          break;
      }
      return this.$t(title);
    },
    okButtonText() {
      if (this.step === STEP_BASE && this.mode === 'add')
        return this.$t('dialogue.footer.next');
      return this.$t('dialogue.footer.ok');
    },
    showCancel() {
      return !this.inviteParticipants && this.step !== STEP_MEMBERS;
    },
    previewStyle() {
      return {
        backgroundImage: `url(${this.preview})`
      };
    },
    uploadInProgress() {
      return !!this.upload;
    },

    fileName() {
      return this.item.logo?.name || this.form.file?.name || '';
    },
    canSetLogo() {
      return this.mode === 'edit' && this.features?.setLogoOnWorkspaces;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useBoardStore, [
      'modify',
      'searchParticipants',
      'emailExists'
    ]),
    init() {
      const {
        name = '',
        private: isPrivate = false,
        protected: isProtected = false,
        sharingAllowed = true,
        copyAllowed = false,
        sendAllAddNotifications = false,
        logo
      } = this.item;

      this.form = {
        name,
        private: isPrivate,
        protected: isProtected,
        sharingAllowed,
        copyAllowed,
        sendAllAddNotifications,
        file: null
      };
      this.preview = logo?.url || '';
    },
    async ok() {
      switch (this.step) {
        case STEP_BASE:
          if (!this.submitted) {
            if (!this.isValid) {
              this.focus();
              return;
            }

            this.submitted = true;

            try {
              const { logo, ...item } = this.item;
              delete item.logo;

              const { file, ...form } = this.form;
              const data = this.item.id
                ? {
                    ...diff(item, form),
                    file,
                    id: item.id
                  }
                : {
                    ...form
                  };

              if (this.item.id && logo && !this.preview) {
                data.logo = null;
              }

              const board = await this.modify(data);

              if (board.error) {
                this.displayError({ message: board.error });
                return;
              }

              if (this.mode === 'edit') {
                this.$emit('success', board);
              } else {
                this.item = { ...board };
                this.init();
                this.displayMembers();
              }

              return;
            } catch (e) {
              if (e.status === 400) {
                this.displayError({ message: e.message });
              } else {
                console.error(e);
                this.displayError({ message: this.$t('boardForm.error') });
              }

              return;
            } finally {
              this.submitted = false;
            }
          }

          break;
        case STEP_MEMBERS:
          this.back(this.inviteParticipants || this.isCreationProcess);
          break;
      }
    },
    clearPreview() {
      if (this.preview) {
        URL.revokeObjectURL(this.preview);
        this.preview = '';
      }
    },
    deleteLogo() {
      this.clearPreview();
      this.form.file = null;
    },
    back(cancel) {
      switch (this.step) {
        case STEP_BASE:
          this.$emit('cancel');
          break;
        case STEP_MEMBERS:
          this.step = STEP_BASE;
          if (cancel) {
            if (this.inviteParticipants || this.isCreationProcess)
              this.$emit('cancel');
          }
          break;
      }

      this.transitionName = 'prev';
    },
    focus() {
      if (this.step === STEP_BASE) {
        const { name } = this.$refs;
        if (name) name.focus();
      }
    },
    displayMembers() {
      this.step = STEP_MEMBERS;
    },
    memberCountChanged(count) {
      this.item = { ...this.item, memberCount: count };
    },
    privateChanged() {
      if (this.form.private) {
        this.form.sharingAllowed = false;
        this.form.copyAllowed = false;
      } else {
        this.form.sharingAllowed =
          this.mode === 'edit' ? this.item.sharingAllowed : false;
        this.form.copyAllowed =
          this.mode === 'edit' && this.form.sharingAllowed
            ? this.item.copyAllowed
            : false;
      }
    },
    sharingChanged() {
      if (this.form.sharingAllowed) {
        this.form.copyAllowed = true;
      } else {
        this.form.copyAllowed = false;
      }
    },

    fileSelected(files) {
      // eslint-disable-next-line
        const [file, ..._rest] = files

      if (file.size > maxSize) {
        this.error = this.$t('boardForm.sizeError', {
          size: this.$n(toMegabytes(file.size), 'decimal'),
          max: this.$n(toMegabytes(maxSize), 'decimal')
        });
        return;
      }

      this.form.file = file;
      this.preview = URL.createObjectURL(file);
    }
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.inviteParticipants) this.displayMembers();
      else this.focus();
    });
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss">
.board-form-dialogue {
  @include next-prev-transition();
  &__logo {
    @include flexy($dir: column, $just: center, $align: center);
    padding: $spacing-half;

    &--display {
      @include grid(
        $gap: 2px,
        $cols: 1fr auto,
        $rows: 50px,
        $areas: 'image trash'
      );

      width: 100%;
      border-radius: $base-border-radius;
      background-color: #fff;
      padding: $spacing-half;
    }

    &--image {
      width: 100%;
      height: 100%;
      grid-area: image;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &--trash {
      grid-area: trash;
    }

    > .file-input {
      background-color: $board-base;
      color: $font-light;
      border: 1px solid $board-base;
      margin-top: 0;
      padding: 0 $spacing-base;
      width: auto;
    }
  }

  &__member {
    > .dialogue__box {
      min-height: 410px;
      max-height: 100%;
    }
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $board-base;
  }

  .form-element {
    background-color: $background-light;
    height: 100%;
    width: 100%;
  }
}
</style>
