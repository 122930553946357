import { mapActions, mapState } from 'pinia';
import { useClipboardStore } from '@/stores/clipboard';
import { BOARD_TYPE, FOLDER_TYPE, MEDIA_TYPE } from '../../utils/types';
import { isItemProtected, isItemAuthorBoard } from '../../utils/asset';
import authMixins from './auth';

export default {
  mixins: [authMixins],
  computed: {
    ...mapState(useClipboardStore, {
      clipboardAssets: 'assets',
      selectedAssetType: 'selectedAssetType',
      clipboardAction: 'action',
      clipboardCurrent: 'current',
      clipboardExecuteAction: 'executeAction',

      isSelectionMode: 'isSelectionMode',
      isSearchSelectionMode: 'isSearchSelectionMode'
    }),
    isSelected() {
      return this.clipboardAssets.has(this.item.id);
    }
  },
  methods: {
    ...mapActions(useClipboardStore, {
      addToClipboard: 'addItem',
      deleteFromClipboard: 'deleteItem',
      enterCopy: 'enterSelectionCopy',
      enterMove: 'enterSelectionMove',
      enterEvaluation: 'enterSelectionEvaluation',
      clearClipboard: 'clearAssets',
      quitSelection: 'quitSelectionMode',
      enterSearchCopy: 'enterSearchSelectionCopy',
      quitSearchSelection: 'quitSearchSelectionMode',
      setCurrentLocation: 'setCurrentLocation',
      setExecuteActionClipboard: 'setExecuteAction'
    }),
    canCopy(item) {
      const { type } = item;

      if (type === BOARD_TYPE) {
        if (this.clipboardAction === 'evaluation') return false;
        else if (!this.isAdministrator) return false;
        else if (item.sandBox) return false;
        else if (!item.copyAllowed && item.author.id !== this.currentUser.id)
          return false;
      } else if (type === FOLDER_TYPE) {
        if (this.clipboardAction === 'evaluation') return false;
      } else if (type === MEDIA_TYPE) {
        if (this.clipboardAction === 'evaluation') return false;

        const { fileInfos } = item;
        if (fileInfos && (fileInfos.processing || fileInfos.error))
          return false;
      }

      if (type !== BOARD_TYPE) {
        if (!item.board?.copyAllowed) {
          if (!item.partOfParticipants) return false;
          else if (
            this.clipboardAction === 'evaluation' &&
            item.board.authorId !== this.currentUser.id
          )
            return false;
        }
      }

      let value =
        this.clipboardAssets.size === 0 ||
        !this.selectedAssetType ||
        this.selectedAssetType === type;

      if (this.clipboardAction === 'evaluation') {
        value = !this.item.brief && this.item.mediaCount > 0;
      }

      return value;
    },
    canMove(item) {
      if (item.type === BOARD_TYPE) return false;

      if (!this.canCopy(item)) return false;
      if (item.evaluationId) return false;

      if (
        isItemProtected(item) &&
        !isItemAuthorBoard(item, this.currentUser.id)
      ) {
        return false;
      }

      return true;
    },
    enterSelectionCopy() {
      this.enterCopy();
    },
    enterSelectionMove() {
      this.enterMove();
    },
    async enterSelectionEvaluation(payload) {
      await this.enterEvaluation(payload);
    },
    quitSelectionMode() {
      this.quitSelection();
    },
    enterSearchSelectionCopy() {
      this.enterSearchCopy();
    },
    quitSearchSelectionMode() {
      this.quitSearchSelection();
    }
  }
};
