<template>
  <div
    class="group-members-section"
    :class="$attrs.class"
    @keydown.enter="keyEnterCheck"
  >
    <div class="group-members-section__content">
      <transition :name="transitionName" @afterEnter="focus">
        <div
          v-if="step === 'main'"
          key="main"
          class="group-members-section__main"
        >
          <AutoComplete
            ref="searchAutoComplete"
            v-if="canEdit"
            :label="$t('groupForm.members.email')"
            :options="options"
            @option:selected="addFromAccount"
            @v-search="search"
          >
            <template #no-options>
              <div style="height: 0"></div>
            </template>
            <template #option="option">
              <div>{{ option.firstName }} {{ option.lastName }}</div>
              <div>{{ option.email }}</div>
            </template>
          </AutoComplete>

          <ListPageable
            displayType="list"
            itemKey="email"
            :items="members"
            :load="load"
            :hasNextPage="hasNextPage"
            :isEmpty="members.length === 0"
          >
            <template #content-item="{ item }">
              <ListParticipant
                :participant="item"
                :restricted="item.restricted"
                :showDelete="canEdit"
                @delete="confirmDelete"
                :selectable="selectToDelete"
                @selectionChanged="selectionChanged(item, $event)"
              >
                <template #info="participant">
                  <span
                    v-if="participant.account"
                    class="group-members-section__list--item--text"
                    >{{ participant.account.firstName }}
                    {{ participant.account.lastName }}</span
                  >
                  <span
                    v-else
                    class="group-members-section__list--item--text"
                    >{{ participant.email }}</span
                  >
                </template>
              </ListParticipant>
            </template>
            <template #content-empty>
              <span>{{ $t('groupForm.members.emptyText') }}</span>
            </template>
          </ListPageable>
        </div>

        <div
          v-else-if="step === 'invite-multiple'"
          key="invite-multiple"
          class="group-members-section__invite-multiple"
        >
          <FormFieldMemo
            ref="inviteMultiple"
            v-model="inviteMultipleEmails"
            :label="$t('groupForm.members.insertEmailList')"
            @keydown.enter.prevent="ok"
          />
        </div>
      </transition>
    </div>

    <footer v-if="displayFooter" class="group-members-section__footer">
      <button
        v-if="displayCancel"
        class="text-button group-members-section__footer__cancel"
        @click="cancel"
      >
        {{ cancelText }}
      </button>

      <div class="text-button group-members-section__footer__custom">
        <MemberOptions
          v-if="displayOptions"
          type="group"
          @selected="optionSelected"
          :canInviteMultiple="false"
          :showDelete="members.length > 0"
        />
      </div>

      <button
        class="text-button group-members-section__footer__ok"
        :disabled="!canApply"
        @click="ok"
      >
        {{ applyText }}
      </button>
    </footer>

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      @ok="deleteItem"
      @cancel="clearDeleteInfo"
    >
      {{ $t('groupForm.members.confirmDelete') }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmDeleteSelectedItems'"
      prompt
      level="warning"
      @ok="deleteSelectedItems"
      @cancel="toggleToast()"
    >
      {{ $t('groupForm.members.confirmDeleteSelectedItems') }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmDeleteAll'"
      prompt
      level="warning"
      @ok="deleteAllItems"
      @cancel="toggleToast()"
    >
      {{ $t('groupForm.members.confirmDeleteAll') }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useGroupStore } from '@/stores/group';
import ToastAlert from '../toast/ToastAlert';
import AutoComplete from '../forms/AutoComplete';
import ListPageable from '../layout/ListPageable';
import ListParticipant from '../layout/ListParticipant';
import FormFieldMemo from '../forms/FormFieldMemo';
import MemberOptions from '../member/MemberOptions';
import toggleState from '../mixins/toggleState';
import authMixins from '../mixins/auth';
import tools from '../../utils/tools';

export default {
  inheritAttrs: false,
  name: 'GroupMembersSection',
  mixins: [authMixins, toggleState('toast')],
  components: {
    ToastAlert,
    AutoComplete,
    ListPageable,
    ListParticipant,
    FormFieldMemo,
    MemberOptions
  },
  props: {
    group: {
      type: Object,
      required: true
    },
    showPrevious: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: null,
      members: [],
      hasNextPage: false,
      hasPrevPage: false,
      searchText: '',
      options: [],
      inviteMultipleEmails: '',
      displayFooter: true,
      selectToDelete: false,
      selectedCount: 0,
      submitted: false,
      step: 'main',
      transitionName: 'prev'
    };
  },
  computed: {
    canEdit() {
      return this.group.author.id === this.currentUser.id;
    },
    displayCancel() {
      return (
        this.showPrevious ||
        this.step === 'invite-multiple' ||
        this.selectToDelete
      );
    },
    cancelText() {
      return this.step === 'invite-multiple' || this.selectToDelete
        ? this.$t('dialogue.footer.cancel')
        : this.$t('dialogue.footer.previous');
    },
    applyText() {
      if (this.step === 'invite-multiple')
        return this.$t('groupForm.members.invite');
      else if (this.selectToDelete) return this.$t('groupForm.members.delete');
      return this.$t('dialogue.footer.ok');
    },
    displayOptions() {
      return (
        this.canEdit && this.step !== 'invite-multiple' && !this.selectToDelete
      );
    },
    canApply() {
      if (this.step === 'invite-multiple') {
        return (this.inviteMultipleEmails || '').trim();
      } else if (this.selectToDelete) {
        return this.selectedCount > 0;
      }

      return true;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayInfo', 'displayError']),
    ...mapActions(useGroupStore, {
      searchMembers: 'searchMembers',
      getMembers: 'apiGetMembers',
      updateMembers: 'updateMembers',
      inviteMultipleMembers: 'inviteMultipleMembers',
      emailExists: 'emailExists'
    }),
    async load(options = {}) {
      const { start = this.members.length } = options;

      try {
        const { items, hasNextPage, hasPrevPage } = await this.getMembers({
          id: this.group.id,
          options: {
            query: { start }
          }
        });

        const members = items.map((m) => this.mapMember(m));
        this.members = start === 0 ? members : [...this.members, ...members];
        this.hasNextPage = hasNextPage;
        this.hasPrevPage = hasPrevPage;

        return { items, hasNextPage, hasPrevPage };
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    async ok() {
      if (this.step === 'invite-multiple') {
        if (!this.canApply) {
          this.focus();
          return;
        }

        await this._inviteMultipleMembers();
      } else if (this.selectToDelete) {
        this.toggleToast('confirmDeleteSelectedItems');
      } else {
        this.$emit('ok');
      }
    },
    async cancel() {
      if (this.step === 'invite-multiple') {
        this.inviteMultipleEmails = '';
        this.transitionName = 'prev';
        this.step = 'main';
      } else if (this.selectToDelete) {
        this.selectToDelete = false;
        this.members.forEach((item) => {
          item.selected = false;
        });
        this.selectedCount = 0;
      } else {
        this.$emit('cancel');
        this.displayFooter = false;
      }
    },
    mapMember(member) {
      const restricted = member.account
        ? !this.userIsParticipant(member.account)
        : false;
      return { ...member, restricted };
    },
    async addFromAccount(account) {
      try {
        if (!account) return;

        const item = {
          email: account.email,
          accountId: account.id,
          pending: false
        };
        const group = await this.updateMembers({
          id: this.group.id,
          membersToAdd: [item]
        });

        if (group.errorMessage) {
          this.displayError({
            message: this.$t(`group.members.errors.${group.errorMessage}`, [
              account.email
            ])
          });
          this.focus();
          return;
        }

        this.displayInfo({
          message: this.$t('groupForm.messages.memberAddedSuccessfully', [
            `${account.firstName} ${account.lastName} &lt;${account.email}&gt;`
          ])
        });

        this.$emit('countChanged', group.memberCount);
        await this.refresh();
        this.options = [];
        this.clearSearch();
        this.focus();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    async search(text) {
      this.searchText = (text || '').trim();
      this.options = await this.searchMembers({
        id: this.group.id,
        text: this.searchText
      });
    },
    clearSearch() {
      const { searchAutoComplete } = this.$refs;
      searchAutoComplete.clear();
      searchAutoComplete.focus();
    },
    confirmDelete(item) {
      this.current = item;
      this.toggleToast('confirmDelete');
    },
    async deleteItem() {
      try {
        const { id, email, account } = this.current;
        const group = await this.updateMembers({
          id: this.group.id,
          membersToDelete: [id]
        });

        if (account) {
          this.displayInfo({
            message: this.$t('groupForm.messages.memberDeletedSuccessfully', [
              `${account.firstName} ${account.lastName} &lt;${account.email}&gt;`
            ])
          });
        } else {
          this.displayInfo({
            message: this.$t('groupForm.messages.memberDeletedSuccessfully', [
              email
            ])
          });
        }

        this.$emit('countChanged', group.memberCount);
        await this.refresh();
        this.clearDeleteInfo();
        this.clearSearch();
        this.focus();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    clearDeleteInfo() {
      this.current = null;
      this.toggleToast();
    },
    async _inviteMultipleMembers() {
      if (this.submitted) return;

      this.submitted = true;
      try {
        const group = await this.inviteMultipleMembers({
          id: this.group.id,
          emails: this.inviteMultipleEmails
        });

        this.$emit('countChanged', group.memberCount);
        await this.refresh();

        this.inviteMultipleEmails = '';
        this.transitionName = 'prev';
        this.step = 'main';
      } catch (e) {
        let message;

        if (e.code) {
          message = this.$t(`groupForm.members.errors.${e.code}`);

          if (e.data) {
            const div = document.createElement('div');
            if (e.code === 'EmailsAreInvalid') {
              const emails = e.data.map((x) => {
                div.innerText = x.input;
                return div.innerHTML;
              });
              message += '<br/>' + emails.join('<br/>');
            } else {
              const accounts = e.data.map((x) => {
                div.innerText = `${x.email} <${x.name}>`;
                return div.innerHTML;
              });
              message += '<br/>' + accounts.join('<br/>');
            }
          }
        } else {
          message = this.$t('global.error');
        }

        this.displayError({ message, timeout: 0 });
      } finally {
        this.submitted = false;
      }
    },
    async deleteSelectedItems() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          this.toggleToast();

          const membersToDelete = this.members
            .filter((x) => x.selected)
            .map((x) => x.id);
          const group = await this.updateMembers({
            id: this.group.id,
            membersToDelete
          });

          this.selectToDelete = false;
          this.selectedCount = 0;

          this.$emit('countChanged', group.memberCount);
          await this.refresh();

          this.clearSearch();
          this.focus();
        } catch (e) {
          this.toggleToast();
          console.error(e);
          this.displayError({ message: this.$t('global.error') });
        } finally {
          this.submitted = false;
        }
      }
    },
    async deleteAllItems() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          const group = await this.updateMembers({
            id: this.group.id,
            deleteAll: true
          });

          this.$emit('countChanged', group.memberCount);
          await this.refresh();

          this.toggleToast();
          this.clearSearch();
          this.focus();
        } catch (e) {
          this.toggleToast();
          console.error(e);
          this.displayError({ message: this.$t('global.error') });
        } finally {
          this.submitted = false;
        }
      }
    },
    optionSelected(option) {
      if (option === 'inviteMultiple') {
        this.step = 'invite-multiple';
      } else if (option === 'selectToDelete') {
        this.selectToDelete = true;
      } else if (option === 'deleteAll') {
        this.toggleToast('confirmDeleteAll');
      }
    },
    async refresh() {
      await this.load({ start: 0 });
    },
    selectionChanged(member, selected) {
      member.selected = selected;

      if (member.selected) this.selectedCount++;
      else this.selectedCount--;
      if (this.selectedCount < 0) this.selectedCount = 0;
    },
    keyEnterCheck(e) {
      if (this.step !== 'main') return;
      if (!this.searchText) {
        e.preventDefault();
        this.$emit('ok');
      }
    },
    focus() {
      if (this.step === 'main') {
        const { searchAutoComplete } = this.$refs;
        searchAutoComplete.focus();
      } else if (this.step === 'invite-multiple') {
        const { inviteMultiple } = this.$refs;
        inviteMultiple.focus();
      }
    }
  },
  async beforeMount() {
    await this.refresh();
  },
  mounted() {
    this.focus();
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss" scoped>
.group-members-section {
  @include flexy($dir: column);
  width: 100%;
  height: 100%;
  flex: 1;

  &__content {
    @include next-prev-transition();

    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;
  }

  &__main {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    flex: 1;
  }

  &__invite-multiple {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    flex: 1;
    padding: $spacing-base;

    > * {
      flex: 1;
    }

    input {
      flex: 1;
    }
  }

  &__footer {
    @include flexy($align: center, $just: space-between);
    background-color: $group-base;
    color: $font-light;
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
    padding: 0 $spacing-base;

    &__custom {
      @include flexy($align: center);
    }

    .text-button {
      font-size: $font-size-large;
    }
  }
}
</style>
