import { mapActions, mapState } from 'pinia';
import { useDragAndDropStore } from '@/stores/drag-and-drop';
import {
  BOARD_TYPE,
  FOLDER_TYPE,
  CARD_TYPE,
  MEDIA_TYPE,
  SHARE_ASSET_TYPE
} from '../../utils/types';
import { isItemProtected, isItemAuthorBoard } from '../../utils/asset';
import selectionClipboardMixin from './selectionClipboard';
import { useAppStore } from '@/stores/app';

export default {
  mixins: [selectionClipboardMixin],

  props: {
    parentHasBrief: {
      type: Function,
      default: null
    },
    fromSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useDragAndDropStore, {
      dragAndDropActivated: 'activated',
      moveDragItem: 'dragItem'
    }),
    isReadOnly() {
      if (!this.item) return true;
      if (this.isShared) return true;
      return !this.item.partOfParticipants || this.item.evaluationId;
    },
    isProtected() {
      return this.itemIsProtected(this.item);
    },
    sharingAllowed() {
      if (!this.item.board) return false;
      return !!this.item.board.sharingAllowed;
    },
    isAuthorBoard() {
      return this.itemIsAuthorBoard(this.item);
    },
    boardType() {
      return BOARD_TYPE;
    },
    folderType() {
      return FOLDER_TYPE;
    },
    cardType() {
      return CARD_TYPE;
    },
    isMediaNote() {
      if (!this.item) return false;
      return this.item.type === MEDIA_TYPE && this.item.mediaType === 'note';
    },
    isPrivate() {
      if (!this.item.board) return false;
      return !!this.item.board.private;
    },
    isSandBox() {
      if (!this.item.board) return false;
      return !!this.item.board.sandBox;
    },
    isHidden() {
      return !!this.item.hidden;
    },
    isDeleted() {
      if (!this.item) return false;
      return !!this.item.deleted;
    },
    isFavorite() {
      return !!this.item.favorite;
    },
    canCopyItem() {
      if (this.isShared) return false;

      if (this.item.type === BOARD_TYPE) {
        return !this.isSandBox && this.canCopy(this.item);
      } else {
        return this.canCopy(this.item);
      }
    },
    canMoveItem() {
      if (this.isReadOnly || this.isDeleted || this.item?.shareAssetId)
        return false;

      if (this.item.type === BOARD_TYPE) return false;
      else {
        return !this.fromSearch && this.canMove(this.item);
      }
    },
    displayAssetState() {
      return (
        this.isProtected ||
        this.isDeleted ||
        this.isReadOnly ||
        this.isHidden ||
        !this.sharingAllowed ||
        this.isPrivate ||
        this.isFavorite ||
        this.displaySendAllAddNotifications
      );
    },
    displaySendAllAddNotifications() {
      if (this.item.type !== BOARD_TYPE || !this.item.board) return false;
      const { subscription } = this.item.board;

      if (
        subscription &&
        subscription.options &&
        subscription.options.disableAdminNotifications
      )
        return false;
      return this.item.board.sendAllAddNotifications;
    },
    isShared() {
      return this.$route.name.endsWith('-share');
    },
    hasComments() {
      return (
        this.item &&
        !this.item.evaluationId &&
        !this.isShared &&
        this.item.hasUnreadComments !== null
      );
    },
    displayCommentIndicator() {
      return false;
      // return !this.isReadOnly && this.hasComments;
    },
    dragging() {
      if (!this.dragAndDropActivated) return false;
      if (!this.moveDragItem) return false;
      return (
        this.moveDragItem.type === this.item.type &&
        this.moveDragItem.id === this.item.id
      );
    },
    tagColor() {
      const { tag } = this.item;
      return tag ? tag.color : null;
    },
    displayFooter() {
      return !this.isShared;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar']),
    itemIsProtected(item) {
      return isItemProtected(item);
    },
    itemIsAuthorBoard(item) {
      if (this.isShared) return false;
      return isItemAuthorBoard(item, this.currentUser?.id);
    },
    copyLink() {
      this.copyTextToClipboard(
        `${window.location.origin}/#/${this.item.type}/${this.item.id}`
      );
    },
    copyTextToClipboard(text) {
      const el = document.createElement('textarea');
      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    navigateToParent() {
      if (!this.item || !this.item.parent) {
        this.navigate({ name: 'root' });
      } else {
        const { id, type } = this.item.parent;
        this.navigate({
          name: type,
          params: { id }
        });
      }

      this.$emit('navigate');
      this.$emit('cancel');
    },
    displayShareAssetList(close = false) {
      this.setActiveLeftSideBar({ type: SHARE_ASSET_TYPE, item: this.item });
      if (close) this.$emit('cancel');
    }
  }
};
