import { defineStore } from 'pinia';
import { getShares as apiGetShares } from '@/api/endpoints/shareAsset';
import { useAuthStore } from '@/stores/auth';
import { SHARE_ASSET_SEARCH_TYPE } from '@/utils/types';

const defaultLimit = 30;

const getKeyStorage = (suffix, currentAssetId) => {
  const { currentUser } = useAuthStore();
  if (!currentUser) return '';
  const { company, id } = currentUser;
  const key = `share-asset_${suffix}-${company.id}-${id}`;
  return currentAssetId ? `${key}-asset` : key;
};

const getFilter = (currentAsset) => {
  const key = getKeyStorage('filter', currentAsset);
  let filter = null;

  const localValue = localStorage.getItem(key);
  if (localValue) {
    filter = JSON.parse(localValue);
    delete filter.allShares;
    delete filter.myShares;
    filter = Object.keys(filter).length ? filter : null;
  }

  const sessionValue = sessionStorage.getItem(key);
  if (sessionValue) {
    const sessionFilter = JSON.parse(sessionValue);
    filter = filter ? { ...filter, ...sessionFilter } : sessionFilter;
  }

  return filter;
};

const getSearchText = (currentAsset) => {
  return localStorage.getItem(getKeyStorage('search-text', currentAsset)) || '';
};

const initialState = {
  items: [],
  hasNextPage: false,
  searchInProgress: false,

  searchText: '',
  filter: undefined,
  hasSearchCriterias: false,

  currentAsset: null
};

export const key = SHARE_ASSET_SEARCH_TYPE;
export const useShareAssetSearchStore = defineStore(key, {
  state: () => ({ ...initialState }),
  getters: {
    paidNamedWebLink: () => !!useAuthStore().features?.paidNamedWebLink
  },
  actions: {
    async reset() {
      Object.assign(this, initialState);
    },
    async getShares(options = {}) {
      this.searchInProgress = true;
      const { filter, items, searchText } = this;
      let { start = items.length, text } = options;
      if (text === undefined) text = searchText;

      try {
        const limit =
          start === 0 && items.length > defaultLimit
            ? items.length
            : defaultLimit;

        if (this.currentAsset?.id || text || filter) {
          const { items: newItems, hasNextPage } = await apiGetShares({
            query: { start, sort: { creationDate: 'asc' }, limit },
            text: text || '',
            assetId: this.currentAsset?.id,
            ...(filter || {})
          });

          this.items = start === 0 ? newItems : [...items, ...newItems];
          this.hasNextPage = hasNextPage;

          return { items: newItems, hasNextPage };
        } else {
          this.items = [];
          this.hasNextPage = false;

          return { items: [], hasNextPage: false };
        }
      } finally {
        this.searchInProgress = false;
        this.hasSearchCriterias = !!text || !!filter;
      }
    },
    async start(displayMySharesUnreadMessages) {
      if (displayMySharesUnreadMessages) {
        this.setFilter(
          { allMySharesUnreadMessages: true },
          { startSearch: false }
        );
        this.setSearchText('');
      }

      this.filter = getFilter(this.currentAsset);
      this.searchText = getSearchText(this.currentAsset);
      return await this.getShares({ start: 0 });
    },

    async setSearchText(text) {
      this.searchText = text;
      if (text) {
        localStorage.setItem(
          getKeyStorage('search-text', this.currentAsset),
          text
        );
      } else {
        localStorage.removeItem(
          getKeyStorage('search-text', this.currentAsset)
        );
      }
    },
    async setFilter(filter, options = { startSearch: true }) {
      this.filter = filter;
      const key = getKeyStorage('filter', this.currentAsset);

      if (filter) {
        localStorage.setItem(
          key,
          JSON.stringify({ ...filter, allShares: undefined })
        );
        sessionStorage.setItem(
          key,
          JSON.stringify({ allShares: filter.allShares })
        );
      } else {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
      }

      if (options?.startSearch) await this.getShares({ start: 0 });
    },
    async search({ text }) {
      this.searchText = text;
      await this.getShares({ start: 0 });
    },
    async clearSearch() {
      this.searchText = null;
      await this.getShares({ start: 0 });
    },
    async clearFilters() {
      this.filter = null;
      this.searchText = '';

      await this.getShares({ start: 0 });
    },
    async setCurrentAsset(item) {
      this.currentAsset = { ...item };
      this.filter = getFilter(this.currentAsset);
      this.searchText = getSearchText(this.currentAsset);
      await this.getShares({ start: 0 });
    },
    async assetExists(options = {}) {
      const { id } = options;
      return this.currentAsset?.id === id;
    },
    async assetUpdated(item) {
      if (!item) return;

      if (this.currentAsset) {
        if (this.currentAsset.id === item.id) this.currentAsset = { ...item };
      } else if (this.items.some((x) => x.assetId === item.id)) {
        await this.getShares({ start: 0 });
      }
    },
    async assetDeleted({ id }) {
      if (this.items.some((x) => x.assetId === id)) {
        await this.getShares({ start: 0 });
      }

      if (this.currentAsset?.id === id) {
        await this.reset();
      }
    },

    async itemAdded({ id }) {
      if (this.currentAsset && !this.items.some((x) => x.id === id)) {
        await this.getShares({ start: 0 });
      }
    },
    async itemUpdated({ id }) {
      const exists = this.items.some((x) => x.id === id);
      if (exists) await this.getShares({ start: 0 });
    },
    async itemDeleted({ id }) {
      if (this.items.some((x) => x.id === id)) {
        await this.getShares({ start: 0 });
      }
    }
  }
});

export default {
  key,
  use: useShareAssetSearchStore
};
