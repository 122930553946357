import {
  get as httpGet,
  post as httpPost,
  del as httpDelete
} from '../requests';

export const ENDPOINT_URL = '/comments';

const getEndPointUrl = (typeEndpoint, parentId, id) => {
  const idPart = id ? `/${id}` : '';
  return `${typeEndpoint}/${parentId}${ENDPOINT_URL}${idPart}`;
};

export const get = async (typeEndpoint, parentId, id, share) => {
  const url = `${getEndPointUrl(typeEndpoint, parentId, id)}${share ? '/share' : ''}`;
  const response = await httpGet(url);
  return response.json();
};

export const getAll = async (typeEndpoint, parentId, options = {}, share) => {
  const response = await httpPost(
    `${getEndPointUrl(typeEndpoint, parentId)}/getAll${share ? '/share' : ''}`,
    options
  );
  return response.json();
};

export const create = async (typeEndpoint, parentId, data, share) => {
  const url = `${getEndPointUrl(typeEndpoint, parentId)}${share ? '/share' : ''}`;
  const response = await httpPost(url, data);
  return response.json();
};

export const del = async (typeEndpoint, parentId, id, share) => {
  const url = `${getEndPointUrl(typeEndpoint, parentId, id)}${share ? '/share' : ''}`;
  const response = await httpDelete(url);
  return response.json();
};
