<template>
  <OptionsDialogue
    v-if="activeDialogue === 'options'"
    class="board-options-dialogue"
    :class="$attrs.class"
    :title="$t('boardOptions.title')"
    :item="item"
    :type="item.type"
    @cancel="$emit('cancel')"
  >
    <ul>
      <AssetMenuItemIcon
        v-if="canEdit && !fromSearch"
        type="board"
        :label="$t('boardOptions.invite')"
        @selected.stop="invite"
      />
      <AssetMenuItemIcon
        v-if="canEdit"
        type="board"
        :label="$t('boardOptions.edit')"
        @selected.stop="edit"
      />
      <AssetMenuItemIcon
        v-if="canManageBackground"
        type="board"
        :label="$t('background.customize')"
        @selected.stop="toggleDialogue('background')"
      />
      <AssetMenuItemIcon
        v-if="canAddComment"
        :label="$t('commentOptions.add')"
        @selected.stop="_displayComments"
      >
        <MessageIcon size="xsmall" />
      </AssetMenuItemIcon>
      <AssetMenuItemIcon
        v-if="canManageSubscription"
        :label="$t('boardOptions.subscription')"
        @selected.stop="toggleDialogue('subscription')"
      >
        <NotificationIcon size="xsmall" />
      </AssetMenuItemIcon>
      <AssetMenuItemIcon
        v-if="canShare"
        :label="$t('boardOptions.share')"
        @selected="displayShareAssetList(true)"
        :diplayOnlyOnSmallWidth="true"
      >
        <ShareIcon size="xsmall" />
      </AssetMenuItemIcon>
      <AssetMenuItemIcon
        v-if="!isSandBox"
        type="board"
        :label="$t('boardOptions.information')"
        @selected.stop="toggleDialogue('information')"
      />

      <AssetCommonOptions v-if="!isSandBox">
        <AssetCommonOption
          :label="$t('options.copy')"
          :disabled="!canCopyItem"
          @selected.stop="addItemToClipboard('copy')"
        >
          <CopyIcon />
        </AssetCommonOption>
        <AssetCommonOption
          v-if="canShare"
          :label="$t('options.share')"
          @selected.stop="displayShareAssetList(true)"
          hideOnSmallWidth
        >
          <ShareIcon />
        </AssetCommonOption>
        <AssetCommonOption
          v-if="canToggleHidden"
          :label="$t(`options.${item.hidden ? 'unhide' : 'hide'}`)"
          @selected.stop="toggleHiddenItem"
        >
          <EyeOpenIcon v-if="isHidden" />
          <EyeCloseIcon v-else />
        </AssetCommonOption>
      </AssetCommonOptions>
    </ul>
  </OptionsDialogue>

  <InformationDialogue
    v-else-if="activeDialogue === 'information'"
    :item="item"
    @cancel="$emit('cancel')"
  />

  <BoardFormDialogue
    v-else-if="activeDialogue === 'editBoard'"
    :editBoard="item"
    :inviteParticipants="inviteParticipants"
    @success="editSuccess"
    @cancel="editCancel"
  />

  <BoardSubscriptionDialogue
    v-else-if="activeDialogue === 'subscription'"
    :asset="item"
    @success="$emit('cancel')"
    @cancel="$emit('cancel')"
  />
  <BackgroundDialogue
    v-else-if="activeDialogue === 'background'"
    :item="item"
    @success="$emit('cancel')"
    @cancel="$emit('cancel')"
  />
</template>

<script>
import OptionsDialogue from '../dialogues/OptionsDialogue';
import AssetMenuItemIcon from '../asset/AssetMenuItemIcon';
import MessageIcon from '../icons/Message';
import NotificationIcon from '../icons/NotificationIcon';
import CopyIcon from '../icons/Copy';
import ShareIcon from '../icons/Share';
import EyeOpenIcon from '../icons/EyeOpen';
import EyeCloseIcon from '../icons/EyeClose';
import AssetCommonOptions from '../asset/AssetCommonOptions';
import AssetCommonOption from '../asset/AssetCommonOption';
import InformationDialogue from '../dialogues/InformationDialogue';
import BoardFormDialogue from './BoardFormDialogue';
import BoardSubscriptionDialogue from './BoardSubscriptionDialogue';
import BackgroundDialogue from '../background/BackgroundDialogue';
import toggleState from '../mixins/toggleState';
import boardMixin from './boardMixin';

export default {
  inheritAttrs: false,
  name: 'BoardOptionsDialogue',
  mixins: [toggleState('dialogue', 'options'), boardMixin],
  components: {
    OptionsDialogue,
    AssetMenuItemIcon,
    MessageIcon,
    NotificationIcon,
    CopyIcon,
    ShareIcon,
    EyeOpenIcon,
    EyeCloseIcon,
    AssetCommonOptions,
    AssetCommonOption,
    BoardFormDialogue,
    InformationDialogue,
    BoardSubscriptionDialogue,
    BackgroundDialogue
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    fromSearch: {
      type: Boolean,
      default: false
    }
  }
};
</script>
