<template>
  <div class="search-filter-menu" :class="$attrs.class">
    <SliderContent
      ref="byTypes"
      class="search-filter-menu__slider-content"
      :label="$t('searchFilterMenu.byTypes')"
      :displayIndicator="false"
      @update:modelValue="expand('byTypes', $event)"
    >
      <div
        class="search-filter-menu__item"
        v-for="type in assetTypes.filter((x) => x !== MEDIA_TYPE)"
        :key="type"
      >
        <BaseCheckbox
          class="search-filter-menu__item--check"
          :label="$t(`types.${type}`)"
          v-model="form.types[type]"
        />
      </div>

      <SliderContent class="search-filter-menu__item--media">
        <template #label>
          <BaseCheckbox
            class="search-filter-menu__item--check"
            :activeMinusState="!!innerHasSelectedMediaTypes"
            :label="$t('types.media')"
            v-model="form.types.media"
            @update:modelValue="typeMediaValueChanged"
          />
        </template>

        <div
          class="search-filter-menu__item search-filter-menu__item--media-type"
          v-for="type in mediaTypes"
          :key="type"
        >
          <BaseCheckbox
            class="search-filter-menu__item--check"
            :label="$t(`mediaTypes.${type}`)"
            v-model="form.mediaTypes[type]"
            @update:modelValue="mediaTypeValuaChanged(type)"
          />
        </div>
      </SliderContent>
    </SliderContent>

    <SliderContent
      ref="byDates"
      class="search-filter-menu__slider-content"
      :label="$t('searchFilterMenu.byDates')"
      @update:modelValue="expand('byDates', $event)"
    >
      <div class="search-filter-menu__item">
        <BaseRadio
          class="search-filter-menu__item--check"
          :label="$t('searchFilterMenu.lastWeek')"
          name="dateOptions"
          value="lastWeek"
          v-model="form.dates.option"
          canUnSelect
          @update:modelValue="optionDateChanged"
        />
      </div>
      <div class="search-filter-menu__item">
        <BaseRadio
          class="search-filter-menu__item--check"
          :label="$t('searchFilterMenu.lastMonth')"
          name="dateOptions"
          value="lastMonth"
          v-model="form.dates.option"
          canUnSelect
          @update:modelValue="optionDateChanged"
        />
      </div>
      <div class="search-filter-menu__item">
        <BaseRadio
          class="search-filter-menu__item--check"
          :label="$t('searchFilterMenu.last3Months')"
          name="dateOptions"
          value="last3Months"
          v-model="form.dates.option"
          canUnSelect
          @update:modelValue="optionDateChanged"
        />
      </div>

      <div class="search-filter-menu__item double">
        <div class="search-filter-menu__item__date">
          <div class="search-filter-menu__item__date--from-label">
            {{ $t('searchFilterMenu.from') }}
          </div>
          <BaseDatetimeInput
            class="search-filter-menu__item__date--from-field"
            name="fromDate"
            :enableTimePicker="false"
            v-model="form.dates.from"
            autoApply
            @update:modelValue="dateSelectionChanged"
          />

          <div class="search-filter-menu__item__date--to-label">
            {{ $t('searchFilterMenu.to') }}
          </div>
          <BaseDatetimeInput
            class="search-filter-menu__item__date--to-field"
            name="toDate"
            :enableTimePicker="false"
            autoApply
            v-model="form.dates.to"
            @update:modelValue="dateSelectionChanged"
          />
        </div>
      </div>
    </SliderContent>

    <div class="search-filter-menu__check">
      <BaseCheckbox
        class="search-filter-menu__check--input"
        :label="$t('searchFilterMenu.myElements')"
        v-model="form.myAssets"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useSearchStore } from '@/stores/search';
import authMixins from '../mixins/auth';
import SliderContent from '../forms/SliderContent';
import { MEDIA_TYPE, ASSET_TYPES, MEDIA_TYPES } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'SearchFilterMenu',
  mixins: [authMixins],
  components: {
    SliderContent
  },
  data() {
    return {
      form: {},
      innerHasSelectedMediaTypes: false
    };
  },
  computed: {
    ...mapState(useSearchStore, ['filter']),
    MEDIA_TYPE() {
      return MEDIA_TYPE;
    },
    assetTypes() {
      return ASSET_TYPES;
    },
    mediaTypes() {
      return MEDIA_TYPES.filter((x) => x !== 'note');
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar', 'displayError']),
    ...mapActions(useSearchStore, ['setFilter']),
    init() {
      const filter = {
        ...(this.filter || {
          types: {},
          mediaTypes: {}
        })
      };

      const form = {
        types: {},
        mediaTypes: {},
        dates: { ...filter.dates } || {
          option: null,
          from: null,
          to: null
        },
        myAssets: !!filter.myAssets
      };

      this.assetTypes.forEach((x) => {
        form.types[x] = !!filter.types[x];
      });
      this.mediaTypes.forEach((x) => {
        form.mediaTypes[x] = !!filter.mediaTypes[x];
      });

      form.dates.option = form.dates.option || null;
      form.dates.from = form.dates.from || null;
      form.dates.to = form.dates.to || null;

      this.form = form;
    },
    typeMediaValueChanged(value) {
      if (!value) {
        for (const p in this.form.mediaTypes) {
          this.form.mediaTypes[p] = false;
        }

        this.innerHasSelectedMediaTypes = false;
        this.$forceUpdate();
      }
    },
    mediaTypeValuaChanged() {
      for (const p in this.form.mediaTypes) {
        if (this.form.mediaTypes[p]) {
          this.form.types.media = true;
          this.innerHasSelectedMediaTypes = true;
          this.$forceUpdate();

          return;
        }
      }

      this.form.types.media = false;
      this.innerHasSelectedMediaTypes = false;
      this.$forceUpdate();
    },
    optionDateChanged() {
      const { dates } = this.form;

      if (dates.option) {
        dates.from = null;
        dates.to = null;
        // this.$forceUpdate();
      }
    },
    dateSelectionChanged() {
      const { dates } = this.form;

      if (dates.from || dates.to) {
        dates.option = null;
        // this.$forceUpdate();
      }
    },
    expand(ref, expanded) {
      if (!expanded) return;

      if (ref === 'byTypes') {
        this.$refs.byDates.close();
      } else if (ref === 'byDates') {
        this.$refs.byTypes.close();
      }
    },
    apply() {
      const setFilter = (filter) => {
        this.setFilter(filter ? { ...filter } : null);
        this.$emit('applied');
      };

      for (const p in this.form.types) {
        if (this.form.types[p]) {
          setFilter(this.form);
          return;
        }
      }

      for (const p in this.form.mediaTypes) {
        if (this.form.mediaTypes[p]) {
          setFilter(this.form);
          return;
        }
      }

      for (const p in this.form.dates) {
        if (this.form.dates[p]) {
          setFilter(this.form);
          return;
        }
      }

      if (this.form.myAssets) {
        setFilter(this.form);
        return;
      }

      setFilter(null);
    }
  },
  beforeMount() {
    this.init();
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';

.search-filter-menu__slider-content > .slider-content__fieldset {
  background-color: $background-dark-3;
  color: $font-dark-2;
  margin-bottom: 1px;
}
</style>

<style lang="scss" scoped>
.search-filter-menu {
  background-color: $background-light;

  &__check {
    @include flexy($align: center);
    height: $vertical-rhythm;
    background-color: $background-dark-3;
    color: $font-dark-2;
    margin-bottom: 1px;
    padding: 0 $spacing-base;
  }
}

.search-filter-menu__item {
  @include flexy($align: center);
  height: $vertical-rhythm;
  width: 100%;
  background-color: $background-light;
  padding: 0 $spacing-base;

  &--media {
    padding: 0 $spacing-half;
  }

  &--media-type {
    padding: 0 ($spacing-base * 2);
  }

  &__date {
    @include grid(
      $gap: $spacing-half,
      $cols: auto auto,
      $rows: auto auto,
      $areas: 'label1 field1' 'label2 field2'
    );

    &--from-label {
      @include grid-item($area: label1, $align: center);
    }

    &--from-field {
      @include grid-item($area: field1, $align: center);
    }

    &--to-label {
      @include grid-item($area: label2, $align: center);
    }

    &--to-field {
      @include grid-item($area: field2, $align: center);
    }
  }
}

.search-filter-menu__item.double {
  height: $vertical-rhythm * 2;
}

.search-filter-menu__item .form-field {
  margin: 0;
}
</style>
