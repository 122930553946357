<template>
  <div
    class="app-left-sidebar"
    :class="[
      { 'app-left-sidebar__hidden': isHidden },
      { 'app-left-sidebar__right-panel-opened': rightPanelIsOpened },
      {
        'app-left-sidebar__right-panel-opened__hidden':
          rightPanelIsOpened && isHidden
      },
      $attrs.class
    ]"
  >
    <NavigationView
      v-if="activeLeftSideBar.type === NAVIGATION_TYPE"
      @toggle="hidePanel"
    />
    <SearchView
      v-else-if="activeLeftSideBar.type === SEARCH_TYPE"
      :mode="activeLeftSideBar.mode"
      @toggle="hidePanel"
    />
    <NotificationView
      v-else-if="activeLeftSideBar.type === NOTIFICATION_TYPE"
      @toggle="hidePanel"
    />
    <SubscriptionView
      v-else-if="activeLeftSideBar.type === SUBSCRIPTION_TYPE"
    />
    <AuditLogin v-else-if="activeLeftSideBar.type === AUDIT_LOGIN_TYPE" />
    <ChatView v-else-if="activeLeftSideBar.type === CHAT_TYPE" />
    <ShareAssetListView
      v-else-if="activeLeftSideBar.type === SHARE_ASSET_TYPE"
      :item="activeLeftSideBar.item"
      :displayMySharesUnreadMessages="
        activeLeftSideBar.displayMySharesUnreadMessages
      "
      @toggle="hidePanel"
    />

    <div
      v-if="activeLeftSideBar.type"
      class="app-left-sidebar__back"
      :class="[
        { 'app-left-sidebar__back--hidden': isHidden },
        { 'app-left-sidebar__back--right-panel-opened': rightPanelIsOpened }
      ]"
    >
      <div class="app-left-sidebar__back--icon" @click="showPanel">
        <Chevron size="small" direction="right" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import NavigationView from '../components/navigation/NavigationView';
import SearchView from '../components/search/SearchView';
import NotificationView from '../components/notification/NotificationView';
import SubscriptionView from '../components/subscription/SubscriptionView';
import AuditLogin from '../components/auditLogin/AuditLogin';
import ChatView from '../components/chat/ChatView';
import ShareAssetListView from '../components/shareAsset/ShareAssetListView';
import Chevron from '../components/icons/Chevron';
import {
  NAVIGATION_TYPE,
  SEARCH_TYPE,
  NOTIFICATION_TYPE,
  SUBSCRIPTION_TYPE,
  AUDIT_LOGIN_TYPE,
  CHAT_TYPE,
  SHARE_ASSET_TYPE
} from '../utils/types';

export default {
  inheritAttrs: false,
  name: 'AppLeftSideBar',
  components: {
    NavigationView,
    SearchView,
    NotificationView,
    SubscriptionView,
    AuditLogin,
    ChatView,
    ShareAssetListView,
    Chevron
  },
  data() {
    return {
      hidden: false
    };
  },
  computed: {
    ...mapState(useAppStore, ['activeLeftSideBar', 'activeRightSideBar']),
    canHide() {
      return (
        this.activeLeftSideBar.type === NAVIGATION_TYPE ||
        this.activeLeftSideBar.type === SEARCH_TYPE ||
        this.activeLeftSideBar.type === NOTIFICATION_TYPE ||
        this.activeLeftSideBar.type === SHARE_ASSET_TYPE
      );
    },
    isHidden() {
      return this.hidden && this.canHide;
    },
    rightPanelIsOpened() {
      return !!this.activeRightSideBar;
    },
    NAVIGATION_TYPE() {
      return NAVIGATION_TYPE;
    },
    SEARCH_TYPE() {
      return SEARCH_TYPE;
    },
    NOTIFICATION_TYPE() {
      return NOTIFICATION_TYPE;
    },
    SUBSCRIPTION_TYPE() {
      return SUBSCRIPTION_TYPE;
    },
    AUDIT_LOGIN_TYPE() {
      return AUDIT_LOGIN_TYPE;
    },
    CHAT_TYPE() {
      return CHAT_TYPE;
    },
    SHARE_ASSET_TYPE() {
      return SHARE_ASSET_TYPE;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar']),
    showPanel() {
      this.hidden = false;
    },
    hidePanel() {
      this.hidden = true;
    },
    async resized() {
      if (this.rightPanelIsOpened && document.body.clientWidth < 1170) {
        if (this.canHide) this.hidePanel();
        else this.setActiveLeftSideBar();
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized);
    window.addEventListener('orientationchange', this.resized);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resized);
    window.removeEventListener('orientationchange', this.resized);
  },
  watch: {
    activeLeftSideBar() {
      if (this.activeLeftSideBar.type) this.showPanel();
    },
    rightPanelIsOpened() {
      this.resized();
    }
  }
};
</script>

<style lang="scss">
.app-left-sidebar {
  @include flexy();
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: $background-dark-2;

  .app-footer {
    background: $background-light-2;
  }

  @media screen and (max-width: #{$medium-size-break - 1}) {
    min-width: 100vw;
    margin-left: 0;
    position: absolute;
    z-index: 1;
    transition: margin-left 0.5s ease-in;

    &__hidden {
      margin-left: -100vw;
      transition: margin-left 0.5s ease-out;
    }
  }

  @media screen and (min-width: #{$medium-size-break}) {
    width: 350px;
    min-width: 350px;
  }

  @media screen and (min-width: #{$medium-size-break + 50}) {
    width: 400px;
    min-width: 400px;
  }

  @media screen and (min-width: #{$medium-size-break}) and (max-width: 1169px) {
    &__right-panel-opened {
      width: unset;
      margin-left: 0;
      position: absolute;
      z-index: 1;
      transition: margin-left 0.5s ease-in;

      &__hidden {
        margin-left: -400px;
        transition: margin-left 0.5s ease-out;
      }
    }
  }

  &__back {
    @include flexy($align: center);
    height: 100%;
    position: fixed;
    left: 100vw;
    z-index: 1;
    transition: left 0.5s ease-in;
    display: none;

    @media screen and (max-width: #{$medium-size-break - 1}) {
      display: inherit;
    }

    &--right-panel-opened {
      @media screen and (min-width: #{$medium-size-break}) and (max-width: 1169px) {
        display: inherit;
      }
    }

    &--hidden {
      left: 0;
      transition: left 0.5s ease-out;
    }

    &--icon {
      @include flexy($align: center);
      height: 100px;
      cursor: pointer;
    }
  }
}
</style>
