import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useCommentStore } from '@/stores/comment';
import { COMMENT_TYPE } from '../../utils/types';

export default {
  computed: {
    ...mapState(useAppStore, ['activeRightSideBar'])
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveRightSideBar']),
    ...mapActions(useCommentStore, {
      loadComments: 'setParent'
    }),
    async showComments(item, type) {
      const data = item || this.item;
      await this.loadComments({
        id: data.id,
        type: type || data.type,
        item: data
      });
      await this.setActiveRightSideBar(COMMENT_TYPE);
    }
  }
};
