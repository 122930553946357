import { mapActions, mapState } from 'pinia';
import { mapStores } from '@/stores';

export const defaultSort = 'positionAsc';
export const defaultDashboardSort = 'dateDesc';

export const sortOptions = {
  nameAsc: { freeName: 'asc', insertedOn: 'desc' },
  nameDesc: { freeName: 'desc', insertedOn: 'desc' },
  dateAsc: { insertedOn: 'asc' },
  dateDesc: { insertedOn: 'desc' },
  positionAsc: { position: 'asc', insertedOn: 'desc' }
};

export const sortShareAssetOptions = {
  nameAsc: { fullTextSearch: 'asc', creationDate: 'desc' },
  nameDesc: { fullTextSearch: 'desc', creationDate: 'desc' },
  dateAsc: { creationDate: 'asc' },
  dateDesc: { creationDate: 'desc' }
};

export const sortDashboardOptions = {
  nameAsc: { freeName: 'asc', creationDate: 'desc' },
  nameDesc: { freeName: 'desc', creationDate: 'desc' },
  dateAsc: { creationDate: 'asc' },
  dateDesc: { creationDate: 'desc' }
};

export function sortableMixin(module) {
  const useStore = mapStores.get(module).use;

  return {
    data() {
      return {
        defaultSort,
        defaultDashboardSort,
        sortOptions,
        sortShareAssetOptions,
        sortDashboardOptions
      };
    },
    computed: {
      ...mapState(useStore, ['sort']),
      sortLabel() {
        return (
          Object.keys(sortOptions).filter((key) => key === this.sort)[0] ||
          defaultSort
        );
      },
      sortDashboardLabel() {
        return (
          Object.keys(sortDashboardOptions).filter(
            (key) => key === this.sort
          )[0] || defaultDashboardSort
        );
      }
    },
    methods: mapActions(useStore, ['setSort'])
  };
}
