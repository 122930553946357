import { defineStore } from 'pinia';

import {
  get as apiGet,
  getAll as apiGetAll,
  markAllAsRead as apiMarkAllAsRead,
  deleteSelected as apiDeleteSelected,
  deleteAll as apiDeleteAll
} from '@/api/endpoints/notification';
import i18n from '@/i18n/index';
import { getSince } from '@/utils/date';
import { NOTIFICATION_TYPE } from '@/utils/types';

const defaultLimit = 30;

const initialState = {
  loading: 0,
  isOpen: false,
  notifications: [],
  filter: {},
  hasNextPage: false
};

const mapSince = (item) => {
  item.creationDate = new Date(item.creationDate);
  const since = getSince(item.creationDate);
  return { ...item, since: i18n.global.t(`since.${since.type}`, since.value) };
};

const map = (item) => {
  const it = mapSince(item);
  const msgParameters = {};

  if (it.target && it.target.name) {
    msgParameters.target = `"${it.target.name.toUpperCase()}"`;
  }

  return {
    ...it,
    name: it.asset.name.toUpperCase(),
    msgParameters
  };
};

export const key = NOTIFICATION_TYPE;
export const useNotificationStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      const { loading } = this;
      Object.assign(this, initialState);
      this.loading = loading;
    },
    async startLoading() {
      this.loading++;
    },
    async stopLoading() {
      this.loading--;
    },
    async assetExists({ id }) {
      if (!this.isOpen) return false;
      return this.notifications.some(
        (x) => x.asset.id === id || x.target?.id === id
      );
    },
    async refresh({ id }) {
      if (!this.isOpen) return;

      await this.startLoading();

      try {
        const item = await apiGet(id);

        if (this.notifications.some((x) => x.id === item.id)) {
          this.notifications = this.notifications.map((x) =>
            x.id !== item.id ? x : map(item)
          );
        } else {
          this.notifications = [map(item), ...this.notifications];
        }
      } finally {
        await this.stopLoading();
      }
    },
    async refreshRead({ ids }) {
      if (!this.isOpen) return;

      this.notifications = this.notifications.map((x) => {
        if (ids.some((id) => x.id === id)) return { ...x, read: true };
        return x;
      });
    },
    async delete({ id }) {
      if (!this.isOpen) return;

      this.notifications = this.notifications.filter((x) => x.id !== id);
    },
    async start() {
      await this.reset();
      this.isOpen = true;
      return await this.get({ start: 0 });
    },
    async get(options = {}) {
      let { filter, notifications } = this;
      const { start = notifications.length } = options;
      const limit =
        start === 0 && notifications.length > defaultLimit
          ? notifications.length
          : defaultLimit;

      await this.startLoading();

      filter = {
        ...(filter.condition || {})
      };

      try {
        const { items, hasNextPage } = await apiGetAll({
          query: { start, limit, filter }
        });
        const newItems = items.map(map);

        this.notifications =
          start === 0 ? newItems : [...notifications, ...newItems];
        this.hasNextPage = hasNextPage;

        return { items: newItems, hasNextPage };
      } finally {
        await this.stopLoading();
      }
    },
    async markAllAsRead() {
      await apiMarkAllAsRead();
      await this.refreshRead({ ids: this.notifications.map((x) => x.id) });
    },
    async deleteSelected(ids) {
      await apiDeleteSelected(ids);

      this.notifications = this.notifications.filter(
        (x) => !ids.some((id) => x.id === id)
      );
    },
    async deleteAll() {
      await apiDeleteAll();

      this.notifications = [];
      this.hasNextPage = false;

      await this.start();
    },
    async setFilter(filter) {
      this.filter = filter;
      await this.get({ start: 0 });
    },
    async assetUpdate(item) {
      if (
        !this.notifications.some(
          (x) => x.asset.id === item.id || x.target?.id === item.id
        )
      )
        return;

      this.notifications = this.notifications.map((x) => {
        let updated = false;

        if (x.asset.id === item.id) {
          x.name = item.name.toUpperCase();
          x.asset.name = item.name;
          updated = true;
        }

        if (x.target?.id === item.id) {
          x.target.name = item.name;
          updated = true;
        }

        return updated ? map(x) : x;
      });
    },
    async assetDeleted({ id }) {
      if (
        this.notifications.some((x) => x.asset.id === id || x.target?.id === id)
      ) {
        await this.get({ start: 0 });
      }
    }
  }
});

export default {
  key,
  use: useNotificationStore
};
