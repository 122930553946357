import io from 'socket.io-client';
import {
  OUTGOING_JOIN,
  OUTGOING_LOGOUT,
  INCOMING_CONNECT,
  INCOMING_CONNECT_TIMEOUT,
  INCOMING_CONNECT_ERROR,
  INCOMING_RECONNECT,
  INCOMING_RECONNECT_ERROR,
  INCOMING_DISCONNECT,
  // INCOMING_AUTHENTICATED,
  INCOMING_USER_JOINED,
  INCOMING_USER_QUIT,
  INCOMING_LOGOUT,
  INCOMING_ERROR,
  INCOMING_MEMBER_ADDED,
  INCOMING_MEMBER_DELETED,
  INCOMING_USER_ADDED_TO_CHAT,
  INCOMING_USER_REMOVED_FROM_CHAT,
  INCOMING_USER_ADDED_TO_BOARD,
  INCOMING_USER_REMOVED_FROM_BOARD,
  INCOMING_ENTITY_ADDED,
  INCOMING_ENTITY_MODIFIED,
  INCOMING_ENTITY_DELETED,
  INCOMING_ASSIGN_ORGANIC_ITEM,
  INCOMING_MOVE_ORGANIC_ITEM,
  INCOMING_UNASSIGN_ORGANIC_ITEMS,
  INCOMING_MOVE_TO_BIN,
  INCOMING_RESTORE_FROM_BIN,
  INCOMING_ENTITY_MOVED,
  INCOMING_UPDATE_POSITIONS_COMPLETED,
  INCOMING_SET_ASSETTAG_COMPLETED,
  INCOMING_REFRESH_USER_CHAT_MESSAGE_COUNT,
  INCOMING_REFRESH_USER_NOTIFICATION_COUNT,
  INCOMING_REFRESH_USER_EVALUATION_COUNT,
  INCOMING_MARKASREAD_NOTIFICATION,
  INCOMING_TEST_CONNECTION
} from './events';

let socket;

const globalEventHandlers = {
  [INCOMING_CONNECT_TIMEOUT]: (timeout) =>
    console.error('Connect timeout', timeout),
  [INCOMING_RECONNECT]: () => console.log('Reconnecting'),
  [INCOMING_RECONNECT_ERROR]: (data) =>
    console.log('attempt to reconnect has failed', data),
  [INCOMING_DISCONNECT]: (data) =>
    console.log('you have been disconnected', data),
  [INCOMING_ERROR]: (data) => console.error('Socket io ERROR', data),
  // [INCOMING_AUTHENTICATED]: data => console.log('received authenticated', data),
  [INCOMING_USER_JOINED]: (data) => console.log('received userJoined', data),
  [INCOMING_USER_QUIT]: (data) => console.log('received userQuit', data)
};

const emit = (event, payload) => {
  if (!socket || socket.disconnected) return;

  return new Promise((resolve, reject) => {
    // console.log('emit', event)
    socket.emit(event, payload, (error, message) => {
      if (error) {
        reject(typeof error === 'string' ? new Error(error) : error);
      } else {
        resolve(message);
      }
    });
  });
};

export const storeEventHandlers = [
  INCOMING_MEMBER_ADDED,
  INCOMING_MEMBER_DELETED,
  INCOMING_USER_ADDED_TO_CHAT,
  INCOMING_USER_REMOVED_FROM_CHAT,
  INCOMING_USER_ADDED_TO_BOARD,
  INCOMING_USER_REMOVED_FROM_BOARD,
  INCOMING_ENTITY_ADDED,
  INCOMING_ENTITY_MODIFIED,
  INCOMING_ENTITY_DELETED,
  INCOMING_ASSIGN_ORGANIC_ITEM,
  INCOMING_MOVE_ORGANIC_ITEM,
  INCOMING_UNASSIGN_ORGANIC_ITEMS,
  INCOMING_MOVE_TO_BIN,
  INCOMING_RESTORE_FROM_BIN,
  INCOMING_ENTITY_MOVED,
  INCOMING_UPDATE_POSITIONS_COMPLETED,
  INCOMING_SET_ASSETTAG_COMPLETED,
  INCOMING_REFRESH_USER_CHAT_MESSAGE_COUNT,
  INCOMING_REFRESH_USER_NOTIFICATION_COUNT,
  INCOMING_REFRESH_USER_EVALUATION_COUNT,
  INCOMING_MARKASREAD_NOTIFICATION,
  INCOMING_LOGOUT,
  INCOMING_TEST_CONNECTION
];

const socketInfo = {
  on: function (event, handler = () => {}) {
    if (!socket || socket.disconnected) return;
    socket.on(event, handler);
  },
  emit,
  logout: async (payload) => {
    await emit(OUTGOING_LOGOUT, payload);
  },
  disconnect: async () => {
    if (!socket || socket.disconnected) return;
    socket.disconnect();
    socket = null;
  }
};

export const getSocket = async (
  eventHandlers,
  refreshTokenCallback,
  authenticatedCallback,
  shareToken
) => {
  const extraHeaders = {
    'x-application-key': 'fassilio-front-key',
    'fassilio-front-key': process.env.APPLICATION_ID
  };
  if (shareToken) extraHeaders['x-share-token'] = shareToken;

  const setNewSocket = () => {
    socket = io(process.env.API_URL, {
      autoConnect: false,
      withCredentials: true,
      extraHeaders
    });

    Object.entries(globalEventHandlers).forEach(([event, handler]) =>
      socket.on(event, handler)
    );

    let refreshTokenHasBeenCalled = false;

    socket.on(INCOMING_CONNECT, async () => {
      refreshTokenHasBeenCalled = false;

      console.log('Socket.io connected', socket.connected);

      await emit(OUTGOING_JOIN);

      if (authenticatedCallback) authenticatedCallback(socketInfo);
    });

    socket.on(INCOMING_CONNECT_ERROR, async (error) => {
      const { message } = error;

      if (message !== 'REFRESH_TOKEN' || refreshTokenHasBeenCalled) {
        console.error(`Socket.io ${INCOMING_CONNECT_ERROR}`, message);
        return;
      }

      refreshTokenHasBeenCalled = true;
      await refreshTokenCallback();
      socket.open();
    });

    socket.open();

    Object.entries(eventHandlers).forEach(([event, handler]) =>
      socket.on(event, handler)
    );
  };
  setNewSocket();

  return socketInfo;
};

export default {
  storeEventHandlers,
  getSocket
};
