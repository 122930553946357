<template>
  <div
    class="member-management"
    :class="$attrs.class"
    @keydown.enter="keyEnterCheck"
  >
    <div class="member-management__content">
      <transition :name="transitionName" @afterEnter="focus">
        <div v-if="step === 'main'" key="main" class="member-management__main">
          <AutoComplete
            ref="searchAutoComplete"
            v-if="canEdit"
            :label="$t('memberManagement.search')"
            :options="options"
            :getOptionKey="getOptionKey"
            :displayAction="type !== 'chat'"
            @option:selected="addFromSelectedItem"
            @v-search="searchMembers"
          >
            <template #no-options>
              <div style="height: 0"></div>
            </template>
            <template #option="option">
              <div class="member-management__option">
                <div class="member-management__option--avatar">
                  <AvatarPill v-if="option.type === 'account'" :info="option" />
                  <GroupIcon v-else type="light" size="small" />
                </div>
                <div v-if="option.type === 'account'">
                  <div>{{ option.firstName }} {{ option.lastName }}</div>
                  <div>{{ option.email }}</div>
                </div>
                <div v-else>
                  <div>{{ option.name }}</div>
                </div>
              </div>
            </template>
            <template v-if="false" #action>
              <button
                class="text-button text-button__search__add"
                :class="`text-button__search__add--${type}`"
                :disabled="!canAddFromSearch"
                @click="addFromSearch"
              >
                {{ $t('memberManagement.add') }}
              </button>
            </template>
          </AutoComplete>

          <slot name="actionBeforeList"></slot>

          <ListPageable
            displayType="list"
            itemKey="email"
            :items="members"
            :load="load"
            :hasNextPage="hasNextPage"
            :isEmpty="members.length === 0"
          >
            <template #content-item="{ item }">
              <ListMember
                :member="item"
                :restricted="item.restricted"
                :showDelete="canEdit"
                @delete="confirmDelete"
                :selectable="selectToDelete"
                @selectionChanged="selectionChanged(item, $event)"
              >
                <template #info="member">
                  <span
                    v-if="member.group"
                    class="member-management__list--item--text"
                    >{{ member.group.name }}</span
                  >
                  <span
                    v-else-if="member.account"
                    class="member-management__list--item--text"
                    >{{ member.account.firstName }}
                    {{ member.account.lastName }}</span
                  >
                  <span v-else class="member-management__list--item--text">{{
                    member.email
                  }}</span>
                </template>
              </ListMember>
            </template>
            <template #content-empty>
              <span>{{ $t('memberManagement.emptyText') }}</span>
            </template>
          </ListPageable>
        </div>

        <div
          v-else-if="step === 'invite-multiple'"
          key="invite-multiple"
          class="member-management__invite-multiple"
        >
          <FormFieldMemo
            ref="inviteMultiple"
            v-model="inviteMultipleEmails"
            :label="$t('memberManagement.insertEmailList')"
            @keydown.enter.prevent="ok"
          />
        </div>
      </transition>
    </div>

    <footer
      v-if="displayFooter"
      class="member-management__footer"
      :class="`member-management__footer--${type}`"
    >
      <button
        v-if="displayPrevious"
        class="text-button member-management__footer__cancel"
        @click="cancel"
      >
        {{ cancelText }}
      </button>

      <div class="text-button member-management__footer__custom">
        <MemberOptions
          v-if="displayOptions"
          :type="type"
          :canInviteMultiple="false"
          @selected="optionSelected"
          :showDelete="members.length > 0"
        />
      </div>

      <button
        class="text-button member-management__footer__ok"
        :disabled="!canApply"
        @click="ok"
      >
        {{ applyText }}
      </button>
    </footer>

    <!-- Creation dialogue -->
    <GroupFormDialogue
      v-if="activeDialogue === 'addNewGroup'"
      :from="from"
      @success="groupCreated"
      @cancel="toggleDialogue()"
    />

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      @ok="deleteItem"
      @cancel="clearDeleteInfo"
    >
      {{ confirmDeleteMessage() }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmDeleteSelectedItems'"
      prompt
      level="warning"
      @ok="deleteSelectedItems"
      @cancel="toggleToast()"
    >
      {{ $t('memberManagement.confirmDeleteSelectedItems') }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmDeleteAll'"
      prompt
      level="warning"
      @ok="deleteAllItems"
      @cancel="toggleToast()"
    >
      {{ $t('memberManagement.confirmDeleteAll') }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useMemberStore } from '@/stores/member';
import ToastAlert from '../toast/ToastAlert';
import toggleState from '../mixins/toggleState';
import AutoComplete from '../forms/AutoComplete';
import ListPageable from '../layout/ListPageable';
import ListMember from '../layout/ListMember';
import FormFieldMemo from '../forms/FormFieldMemo';
import GroupFormDialogue from '../group/GroupFormDialogue';
import GroupIcon from '../icons/Group';
import AvatarPill from '../AvatarPill';
import MemberOptions from './MemberOptions';
import authMixins from '../mixins/auth';
import tools from '../../utils/tools';

export default {
  inheritAttrs: false,
  name: 'MemberManagement',
  mixins: [authMixins, toggleState('dialogue'), toggleState('toast')],
  components: {
    ToastAlert,
    AutoComplete,
    ListPageable,
    ListMember,
    FormFieldMemo,
    GroupFormDialogue,
    GroupIcon,
    AvatarPill,
    MemberOptions
  },
  props: {
    parent: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    showPrevious: {
      type: Boolean,
      default: false
    },
    canInviteMultiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchText: '',
      inviteMultipleEmails: '',
      displayFooter: true,
      selectToDelete: false,
      selectedCount: 0,
      submitted: false,
      step: 'main',
      transitionName: 'prev'
    };
  },
  computed: {
    ...mapState(useMemberStore, [
      'loading',
      'members',
      'hasNextPage',
      'options'
    ]),
    from() {
      return {
        id: this.parent.id,
        type: this.type
      };
    },
    canAddFromSearch() {
      const search = (this.searchText || '').trim().toLowerCase();

      if (!search) return false;
      if (!tools.regExpEmail.test(search)) return false;
      return !(this.options || []).some(
        (x) => x.email.toLowerCase() === search
      );
    },
    displayPrevious() {
      return (
        this.showPrevious ||
        this.step === 'invite-multiple' ||
        this.selectToDelete
      );
    },
    cancelText() {
      return this.step === 'invite-multiple' || this.selectToDelete
        ? this.$t('dialogue.footer.cancel')
        : this.$t('dialogue.footer.previous');
    },
    applyText() {
      if (this.step === 'invite-multiple')
        return this.$t('memberManagement.invite');
      else if (this.selectToDelete) return this.$t('memberManagement.delete');
      return this.$t('dialogue.footer.ok');
    },
    displayOptions() {
      return (
        this.canEdit && this.step !== 'invite-multiple' && !this.selectToDelete
      );
    },
    canApply() {
      if (this.step === 'invite-multiple') {
        return (this.inviteMultipleEmails || '').trim();
      } else if (this.selectToDelete) {
        return this.selectedCount > 0;
      }

      return true;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useMemberStore, {
      load: 'get',
      update: 'update',
      search: 'search',
      emailExists: 'emailExists',
      setParent: 'setParent',
      clearOptions: 'clearOptions',
      inviteMultiple: 'inviteMultiple',
      reset: 'reset'
    }),
    async refresh() {
      await this.load({ start: 0 });
    },
    async ok() {
      if (this.step === 'invite-multiple') {
        if (!this.canApply) {
          this.focus();
          return;
        }

        await this._inviteMultiple();
      } else if (this.selectToDelete) {
        this.toggleToast('confirmDeleteSelectedItems');
      } else {
        this.$emit('ok');
      }
    },
    async cancel() {
      if (this.step === 'invite-multiple') {
        this.inviteMultipleEmails = '';
        this.transitionName = 'prev';
        this.step = 'main';
      } else if (this.selectToDelete) {
        this.selectToDelete = false;
        this.members.forEach((item) => {
          item.selected = false;
        });
        this.selectedCount = 0;
      } else {
        this.$emit('cancel');
        this.displayFooter = false;
      }
    },
    async addFromSelectedItem(item) {
      try {
        if (!item) return;

        let newItem;

        if (item.type === 'account') {
          newItem = {
            email: item.email,
            accountId: item.id,
            pending: false
          };
        } else {
          newItem = {
            groupId: item.id
          };
        }

        const result = await this.update({ membersToAdd: [newItem] });

        if (result && result.errorMessage) {
          if (item.type === 'account') {
            this.displayError({
              message: this.$t(
                `memberManagement.errors.${result.errorMessage}`,
                [item.email]
              )
            });
          } else {
            this.displayError({
              message: this.$t(
                `memberManagement.errors.${result.errorMessage}`,
                [item.name]
              )
            });
          }

          this.focus();
          return;
        }

        await this.refresh();

        if (result) this.$emit('countChanged', result.memberCount);

        this.clearOptions();
        this.clearSearch();
        this.focus();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    async addFromSearch() {
      /*
      try {
        const email = (this.searchText || '').trim().toLowerCase();
        if (!email) return;

        const { isValid } = await this.emailExists(email);
        if (!isValid) {
          this.displayError({
            message: this.$t('global.invalidEmail', [email]),
            timeout: 10000
          });

          this.focus();
          return;
        }

        const item = {
          email,
          pending: true
        };
        const result = await this.update({ membersToAdd: [item] });

        if (result && result.errorMessage) {
          this.displayError({
            message: this.$t(
              `groupForm.members.errors.${result.errorMessage}`,
              [email]
            )
          });
          this.focus();
          return;
        }

        await this.refresh();

        if (result) this.$emit('countChanged', result.memberCount);

        this.clearSearch();
        this.focus();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
      */
    },
    async searchMembers(text) {
      this.searchText = (text || '').trim();
      await this.search({ text: this.searchText });
    },
    clearSearch() {
      this.searchText = '';

      const { searchAutoComplete } = this.$refs;
      if (!searchAutoComplete) return;

      searchAutoComplete.clear();
      searchAutoComplete.focus();
    },
    confirmDeleteMessage() {
      if (!this.current) return;

      const { group, account, email } = this.current;

      if (group) {
        return this.$t('memberManagement.confirmDeleteGroup', [group.name]);
      } else if (account) {
        return this.$t('memberManagement.confirmDeleteMember', [
          `${account.firstName} ${account.lastName}`
        ]);
      } else {
        return this.$t('memberManagement.confirmDeleteMember', [email]);
      }
    },
    confirmDelete(item) {
      this.current = item;
      this.toggleToast('confirmDelete');
    },
    async deleteItem() {
      try {
        const result = await this.update({
          membersToDelete: [this.current.id]
        });
        if (result) this.$emit('countChanged', result.memberCount);

        await this.refresh();

        this.clearDeleteInfo();
        this.clearSearch();
        this.focus();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    clearDeleteInfo() {
      this.current = null;
      this.toggleToast();
    },
    groupCreated(parent) {
      this.refresh();
      if (parent) this.$emit('countChanged', parent.memberCount);
      this.toggleDialogue();
    },
    async _inviteMultiple() {
      if (this.submitted) return;

      this.submitted = true;
      try {
        const result = await this.inviteMultiple({
          emails: this.inviteMultipleEmails
        });

        await this.refresh();

        if (result) this.$emit('countChanged', result.memberCount);

        this.inviteMultipleEmails = '';
        this.transitionName = 'prev';
        this.step = 'main';
      } catch (e) {
        let message;

        if (e.code) {
          message = this.$t(`memberManagement.errors.${e.code}`);

          if (e.data) {
            const div = document.createElement('div');
            if (e.code === 'EmailsAreInvalid') {
              const emails = e.data.map((x) => {
                div.innerText = x.input;
                return div.innerHTML;
              });
              message += '<br/>' + emails.join('<br/>');
            } else {
              const accounts = e.data.map((x) => {
                div.innerText = `${x.email} <${x.name}>`;
                return div.innerHTML;
              });
              message += '<br/>' + accounts.join('<br/>');
            }
          }
        } else {
          message = this.$t('global.error');
        }

        this.displayError({ message, timeout: 0 });
      } finally {
        this.submitted = false;
      }
    },
    async deleteSelectedItems() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          this.toggleToast();

          const membersToDelete = this.members
            .filter((x) => x.selected)
            .map((x) => x.id);
          const result = await this.update({ membersToDelete });

          this.selectToDelete = false;
          this.selectedCount = 0;

          await this.refresh();

          if (result) this.$emit('countChanged', result.memberCount);

          this.clearSearch();
          this.focus();
        } catch (e) {
          this.toggleToast();
          console.error(e);
          this.displayError({ message: this.$t('global.error') });
        } finally {
          this.submitted = false;
        }
      }
    },
    async deleteAllItems() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          const result = await this.update({ deleteAll: true });

          await this.refresh();

          if (result) this.$emit('countChanged', result.memberCount);

          this.toggleToast();

          this.clearSearch();
          this.focus();
        } catch (e) {
          this.toggleToast();
          console.error(e);
          this.displayError({ message: this.$t('global.error') });
        } finally {
          this.submitted = false;
        }
      }
    },
    optionSelected(option) {
      if (option === 'addNewGroup') this.toggleDialogue('addNewGroup');
      else if (option === 'inviteMultiple') {
        this.step = 'invite-multiple';
      } else if (option === 'selectToDelete') {
        this.selectToDelete = true;
      } else if (option === 'deleteAll') {
        this.toggleToast('confirmDeleteAll');
      }
    },
    getOptionKey(option) {
      return `${option.type}_${option.id}`;
    },
    selectionChanged(member, selected) {
      member.selected = selected;

      if (member.selected) this.selectedCount++;
      else this.selectedCount--;
      if (this.selectedCount < 0) this.selectedCount = 0;
    },
    keyEnterCheck(e) {
      if (this.step !== 'main' || this.activeToast) return;
      if (!this.searchText) {
        e.preventDefault();
        this.$emit('ok');
      }
    },
    focus() {
      if (!this.canEdit) return;

      if (this.step === 'main') {
        const { searchAutoComplete } = this.$refs;
        searchAutoComplete.focus();
      } else if (this.step === 'invite-multiple') {
        const { inviteMultiple } = this.$refs;
        inviteMultiple.focus();
      }
    }
  },
  async beforeMount() {
    await this.setParent({
      parent: this.parent,
      parentType: this.type
    });
    await this.refresh();
  },
  mounted() {
    this.focus();
  },
  async beforeUnmount() {
    this.reset();
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss" scoped>
.member-management {
  @include flexy($dir: column);
  width: 100%;
  height: 100%;
  flex: 1;

  &__content {
    @include next-prev-transition();

    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    flex: 1;

    position: relative;
  }

  &__main {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    flex: 1;

    .member-management__option {
      @include flexy($align: center, $just: flex-start);
      min-height: $vertical-rhythm;

      &--avatar {
        @include pill();
        background: $font-dark;
      }

      > * {
        margin-right: $spacing-half;
      }

      > .avatar {
        margin-right: $spacing-third;
      }
    }
  }

  &__invite-multiple {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    flex: 1;
    padding: $spacing-base;

    > * {
      width: 100%;
      flex: 1;
    }
  }

  &__footer {
    @include flexy($align: center, $just: space-between);
    background-color: $background-dark;
    color: $font-light;
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
    padding: 0 $spacing-base;

    &--board {
      background-color: $board-base;
    }

    &--evaluation,
    &--evaluationResult {
      background-color: $evaluation-base;
    }

    &--chat {
      background-color: $chat-base;
    }

    &--share-asset {
      background-color: $share-asset-base;
    }

    &__custom {
      @include flexy($align: center);
    }

    .text-button {
      font-size: $font-size-large;
    }
  }
}

.text-button__search__add {
  height: 100%;
  font-weight: bold;
  padding: $spacing-half;
  text-transform: uppercase;

  &--board {
    background-color: $board-base;
  }

  &--evaluation,
  &--evaluationResult {
    background-color: $evaluation-base;
  }

  &--chat {
    background-color: $chat-base;
  }

  &--share-asset {
    background-color: $share-asset-base;
  }
}
</style>
