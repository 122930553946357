import { getCookie } from '@/utils/tools';
import { useAuthStore } from '@/stores/auth';

const checkRefreshIsNeeded = async (isShared, authStore) => {
  if (!isShared && authStore.shareLogin) {
    await authStore.clearShareLogin();
  } else if (isShared && authStore.isLoggedIn) {
    await authStore.logoutWithoutCallingServer({ redirectToLogin: false });
  }

  if (!isShared && !authStore.isLoggedIn && getCookie('fso-authenticated')) {
    await authStore.refresh({ redirectToLogoutOnUnauthorize: false });
  }
};

export default {
  checkRefreshIsNeeded: async (isShared = false) => {
    await checkRefreshIsNeeded(isShared, useAuthStore());
  },

  ensureUserHasRole: (role) => {
    return async (to) => {
      const authStore = useAuthStore();
      await checkRefreshIsNeeded(false, authStore);

      const { isLoggedIn } = authStore;

      if (!isLoggedIn) {
        return { name: 'login', query: { redirectUrl: to.path } };
      } else if (!authStore.hasRole(role)) {
        return { name: 'root' };
      }
    };
  },

  ensureHasShareToken: () => {
    return async (to, from) => {
      const authenticated = !!useAuthStore().shareLogin;
      if (!authenticated)
        return {
          name: 'share',
          params: { hash: to.params?.hash },
          query: { redirectUrl: to.path }
        };

      if (to?.params?.hash || !from?.params?.hash) return true;

      return {
        ...to,
        params: { ...to.params, hash: from.params.hash }
      };
    };
  }
};
