<template>
  <div ref="container" class="list-page-block" :class="$attrs.class">
    <SliderContent :modelValue="true" :displayTitle="!!title" arrowAlignLeft>
      <template #label>
        <div class="list-page-block__title--container">
          <div class="list-page-block__title">
            <div class="list-page-block__title--actions" @click.prevent.stop>
              <slot name="title-action"></slot>
            </div>
            <div class="list-page-block__title--label">
              {{ title }}
            </div>
          </div>
        </div>
      </template>

      <div
        v-if="displayAddItem || displayDeleteItem"
        class="list-page-block__items"
        :class="`list-page-block__items--${displayStyle}--${type}`"
      >
        <div v-if="displayAddItem" class="list-page-block__items__item">
          <div
            class="list-page-block__items__item--inner"
            :class="`list-page-block__items__item--inner--${displayStyle}--${type}`"
          >
            <slot name="content-add-item"></slot>
          </div>
        </div>
        <div v-if="displayDeleteItem" class="list-page-block__items__item">
          <div
            class="list-page-block__items__item--inner"
            :class="`list-page-block__items__item--inner--${displayStyle}--${type}`"
          >
            <slot name="content-delete-item"></slot>
          </div>
        </div>
      </div>

      <VueDraggable
        v-if="draggable"
        class="list-page-block__items"
        :class="`list-page-block__items--${displayStyle}--${type}`"
        :handle="handle"
        :list="items"
        :item-key="itemKey"
        draggable=".draggable"
        @start="$emit('dragStarted', $event)"
        @end="$emit('dragEnded', $event)"
        @change="orderHasChanged"
      >
        <template #item="{ element: item }">
          <div
            class="list-page-block__items__item"
            :class="{ draggable: _canDragItem(item) }"
          >
            <div
              class="list-page-block__items__item--inner"
              :class="`list-page-block__items__item--inner--${displayStyle}--${type}`"
            >
              <slot name="content-item" v-bind:item="item"></slot>
            </div>
          </div>
        </template>
      </VueDraggable>
      <div
        v-else
        class="list-page-block__items"
        :class="`list-page-block__items--${displayStyle}--${type}`"
      >
        <div
          class="list-page-block__items__item"
          v-for="item in items"
          :key="item[itemKey]"
        >
          <div
            class="list-page-block__items__item--inner"
            :class="`list-page-block__items__item--inner--${displayStyle}--${type}`"
          >
            <slot name="content-item" v-bind:item="item"></slot>
          </div>
        </div>
      </div>

      <div
        v-if="hasNextPage && !autoLoadOnNextPage"
        class="list-page-block__see-more"
        :class="`list-page-block__see-more--${displayStyle}--${type}`"
      >
        <CallToAction size="auto" :disabled="loading" @v-click="loadMore">
          {{ $t('list.seeMore') }}
        </CallToAction>
      </div>
    </SliderContent>
  </div>
</template>

<script>
import SliderContent from '../forms/SliderContent';
import CallToAction from '../forms/CallToAction';
import VueDraggable from 'vuedraggable';
import authMixin from '../mixins/auth';
import paginationMixin from '../mixins/pagination';

export default {
  inheritAttrs: false,
  name: 'ListPageBlock',
  mixins: [authMixin, paginationMixin],
  components: {
    SliderContent,
    CallToAction,
    VueDraggable
  },
  props: {
    type: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    load: {
      type: Function,
      default: null
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    hasNextPage: {
      type: Boolean,
      default: false
    },
    displayAddItem: {
      type: Boolean,
      default: false
    },
    displayDeleteItem: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    canDragItem: {
      type: Function,
      default: null
    },
    displayType: {
      type: String,
      default: ''
    },
    handle: {
      type: String,
      default: null
    },
    autoLoadOnNextPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    displayStyle() {
      if (this.displayType) return this.displayType;
      return this.display;
    }
  },
  methods: {
    scrollTop() {
      const { container } = this.$refs;
      if (container) container.scrollTop = 0;
    },
    async loadMore() {
      if (this.loading) return false;

      if (this.load) {
        this.loading = true;

        this.load()
          .catch((e) => {
            console.error('ListPageBlock => loadMore', e);
            this.displayError({ message: this.$t('global.error') });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    _canDragItem(item) {
      if (!this.draggable) return false;
      if (!this.canDragItem) return true;
      return this.canDragItem(item);
    },
    orderHasChanged(e) {
      if (e.moved) {
        const { element, newIndex } = e.moved;
        this.$emit('reorderElements', {
          element,
          newIndex
        });
      }
    }
  }
};
</script>

<style lang="scss">
.list-page-block {
  &__title--container {
    @include flexy($align: center);
    margin-left: $spacing-base;
  }

  &__title {
    @include flexy($align: center);

    &--actions {
      @include flexy($align: center);
      cursor: default;
    }

    &--label {
      color: $font-title-list-color;
      font-size: $font-size-base + 2;
    }
  }

  &__items {
    margin: $spacing-base;

    &--tile {
      &--board {
        @include grid(
          $gap: $spacing-gutter,
          $cols: repeat(auto-fill, minmax($tile-board-width, 1fr))
        );
      }

      &--folder {
        @include grid(
          $gap: $spacing-gutter,
          $cols: repeat(auto-fill, minmax($tile-folder-width, 1fr))
        );
      }

      &--card {
        @include grid(
          $gap: $spacing-gutter,
          $cols: repeat(auto-fill, minmax($tile-card-width, 1fr))
        );
      }

      &--media {
        @include grid(
          $gap: $spacing-gutter-media,
          $cols: repeat(auto-fill, minmax($tile-media-width, 1fr))
        );
        margin: $spacing-gutter-media;
      }

      &--evaluation {
        @include grid(
          $gap: $spacing-gutter,
          $cols: repeat(auto-fill, minmax($tile-evaluation-width, 1fr))
        );
      }

      &--background {
        @include grid(
          $gap: $spacing-gutter,
          $cols: repeat(auto-fill, minmax($tile-background-width, 1fr))
        );
      }
    }

    &__item {
      display: block;

      &--inner {
        &--tile {
          max-width: 100%;
          min-width: 0;

          &--board {
            flex: 1 1 $tile-board-width;
          }

          &--folder {
            flex: 1 1 $tile-folder-width;
          }

          &--card {
            flex: 1 1 $tile-card-width;
          }

          &--media {
            flex: 1 1 $tile-media-width;
          }

          &--evaluation {
            flex: 1 1 $tile-evaluation-width;
          }

          &--background {
            flex: 1 1 $tile-background-width;
          }
        }
      }
    }
  }

  &__see-more {
    margin: $spacing-gutter $spacing-base;

    > button {
      height: $vertical-rhythm;
      min-height: $vertical-rhythm;
      margin: 0;
      color: $font-title-list-color;
      font-weight: $font-weight-bold;
      background-color: $background-see-more;
    }

    &--tile {
      &--board {
        @include grid(
          $gap: $spacing-gutter,
          $cols: repeat(auto-fill, minmax($tile-board-width, 1fr))
        );
      }

      &--folder {
        @include grid(
          $gap: $spacing-gutter,
          $cols: repeat(auto-fill, minmax($tile-folder-width, 1fr))
        );
      }

      &--card {
        @include grid(
          $gap: $spacing-gutter,
          $cols: repeat(auto-fill, minmax($tile-card-width, 1fr))
        );
      }

      &--media {
        @include grid(
          $gap: $spacing-gutter,
          $cols: repeat(auto-fill, minmax($tile-media-width, 1fr))
        );
      }
    }
  }
}
</style>
