<template>
  <BaseDialogue
    class="card-subscription-dialogue"
    :class="[
      { 'card-subscription-dialogue--brief': asset.brief },
      $attrs.class
    ]"
    :title="title"
    size="small"
    @ok="ok"
    @cancel="$emit('cancel')"
  >
    <div class="form-element">
      <div
        v-if="boardAllAddNotificationsActivated"
        class="form-field card-subscription-dialogue__title warning"
      >
        {{ $t('cardSubscriptionForm.boardAllAddNotificationsActivated') }}
      </div>

      <div class="form-field card-subscription-dialogue__title">
        {{ $t('cardSubscriptionForm.adding.title') }}
      </div>
      <div class="form-field card-subscription-dialogue__checkbox">
        <BaseCheckbox
          name="addingMedia"
          :label="$t('cardSubscriptionForm.adding.media')"
          v-model="form.addingMedia"
          @update:modelValue="valueChanged"
        />
      </div>

      <div class="form-field card-subscription-dialogue__title">
        {{ $t('cardSubscriptionForm.deleting.title') }}
      </div>
      <div class="form-field card-subscription-dialogue__checkbox">
        <BaseCheckbox
          name="deletingMedia"
          :label="$t('cardSubscriptionForm.deleting.media')"
          v-model="form.deletingMedia"
          @update:modelValue="valueChanged"
        />
      </div>

      <div class="form-field">
        <div class="card-subscription-dialogue__options">
          {{ $t('cardSubscriptionForm.options') }}
        </div>

        <div class="card-subscription-dialogue__options--content">
          <BaseCheckbox
            class="form-field"
            name="receiveEmail"
            :label="$t('cardSubscriptionForm.receiveEmail')"
            :disabled="disabledReceiveEmail"
            v-model="form.receiveEmail"
          />
        </div>
      </div>
    </div>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useCardStore } from '@/stores/card';
import BaseDialogue from '../dialogues/BaseDialogue';

export default {
  inheritAttrs: false,
  name: 'CardSubscriptionDialogue',
  components: {
    BaseDialogue
  },
  props: {
    asset: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        addingMedia: false,
        deletingMedia: false,
        receiveEmail: false
      },
      option: null,
      submitted: false
    };
  },
  computed: {
    title() {
      if (this.asset.brief) {
        return this.$t('cardSubscriptionForm.brief.title');
      } else {
        return this.$t('cardSubscriptionForm.title');
      }
    },
    boardAllAddNotificationsActivated() {
      const { board } = this.asset;
      if (!board) return false;
      const { subscription } = board;

      if (subscription && subscription.options) {
        if (subscription.options.addingAllItems) return true;
        else if (subscription.options.disableAdminNotifications) return false;
      }

      return board.sendAllAddNotifications;
    },
    disabledReceiveEmail() {
      return !this.form.addingMedia && !this.form.deletingMedia;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useCardStore, [
      'getSubscription',
      'addSubscription',
      'editSubscription'
    ]),
    async ok() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          const data = {
            options: {
              addingMedia: this.form.addingMedia,
              deletingMedia: this.form.deletingMedia,
              receiveEmail: this.form.receiveEmail
            }
          };

          if (this.entity.id) {
            await this.editSubscription({
              parentId: this.asset.id,
              id: this.entity.id,
              data
            });
          } else {
            await this.addSubscription({
              parentId: this.asset.id,
              data
            });
          }

          this.$emit('success');
        } catch (e) {
          console.error(e);
          this.submitted = false;
          this.displayError({ message: this.$t('global.error') });
        }
      }
    },
    valueChanged() {
      if (this.disabledReceiveEmail) {
        this.form.receiveEmail = false;
      }
    }
  },
  async beforeMount() {
    this.entity = await this.getSubscription({ parentId: this.asset.id });
    if (this.entity.id) {
      const { options } = this.entity;
      this.form = {
        addingMedia: !!options.addingMedia,
        deletingMedia: !!options.deletingMedia,
        receiveEmail: !!options.receiveEmail
      };
    }
  }
};
</script>

<style lang="scss">
.card-subscription-dialogue {
  .dialogue__header,
  .dialogue__footer {
    background-color: $card-base;
  }

  &__title {
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

  &__title.warning {
    color: $error-color;
  }

  &__checkbox {
    margin-left: $spacing-double;
  }

  &--brief {
    .dialogue__header,
    .dialogue__footer {
      background-color: $brief-base;
    }
  }

  &__options {
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }
}
</style>
