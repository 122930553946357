<template>
  <div
    class="comment-item__message"
    :class="[
      {
        'comment-item__message--mine':
          item.author && item.author.id === currentAccountId
      },
      $attrs.class
    ]"
  >
    <AvatarPill
      :info="item.author || {}"
      :displayPicture="!isNamedShareLoggedIn"
    />
    <div class="comment-item__message--right">
      <div class="comment-item__message--info">
        <MessageIcon :size="'xsmall'" />
        <div class="comment-item__message--since">{{ item.since }}</div>
      </div>
      <div class="comment-item__message--content" v-html="item.content"></div>
    </div>
    <div
      v-if="canDelete"
      class="comment-item__message--delete"
      @click="$emit('deleteComment', item)"
    >
      <CloseIcon size="xsmall2" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useCommentStore } from '@/stores/comment';
import authMixins from '../mixins/auth';
import AvatarPill from '../AvatarPill';
import MessageIcon from '../icons/Message';
import CloseIcon from '../icons/Cross';

export default {
  inheritAttrs: false,
  name: 'CommentItem',
  mixins: [authMixins],
  components: {
    AvatarPill,
    MessageIcon,
    CloseIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(useCommentStore, {
      parent: 'parent'
    }),
    currentAccountId() {
      return this.shareLogin?.accountId || this.currentUser?.id;
    },
    canDelete() {
      if (!this.currentAccountId) return false;
      return (
        this.item.author.id === this.currentAccountId ||
        this.parent?.item?.author?.id === this.currentAccountId
      );
    }
  }
};
</script>

<style lang="scss">
.comment-item__message--content a {
  text-decoration: underline;
}
</style>

<style lang="scss" scoped>
.comment-item__message {
  @include flexy();

  background-color: $background-dark;
  padding: $spacing-base;
  border-radius: $tile-border-radius;
  margin-left: 0;
  margin-right: $spacing-triple;
  position: relative;

  &--mine {
    margin-right: 0;
    margin-left: $spacing-triple;
    overflow: hidden;
  }

  &--right {
    @include flexy($dir: column);
    margin-left: $spacing-base;
    overflow: hidden;
  }

  &--info {
    @include flexy($align: flex-end);
  }

  &--since {
    margin-left: $spacing-third;
    font-size: $font-size-xsmall;
  }

  &--content {
    word-wrap: break-word;
    margin-top: $spacing-quarter;
  }

  &--delete {
    @include flexy($align: center, $just: center);
    width: 30px;
    height: 30px;
    top: $spacing-half;
    right: $spacing-half;
    background-color: $background-light;
    position: absolute;
    cursor: pointer;
  }
}

.icon.icon-message-icon {
  fill: $font-light;
  stroke: $font-dark;
}
</style>
