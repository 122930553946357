<template>
  <div class="app-main" :class="$attrs.class">
    <AppLeftSideBar v-if="displayLeftSideBar" />
    <RouterView class="app-content" />
    <AppRightSideBar v-if="displayRightSideBar" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import features from '../utils/features';
import AppLeftSideBar from '../components/AppLeftSideBar';
import AppRightSideBar from '../components/AppRightSideBar';
import authMixins from '../components/mixins/auth';

export default {
  inheritAttrs: false,
  name: 'AppMain',
  mixins: [authMixins],
  components: {
    AppLeftSideBar,
    AppRightSideBar
  },
  computed: {
    ...mapState(useAppStore, ['activeLeftSideBar', 'activeRightSideBar']),
    displayLeftSideBar() {
      return (
        !!this.activeLeftSideBar.type &&
        this.currentUser &&
        !this.isRootAdministrator &&
        !this.isWebShareLoggedIn
      );
    },
    displayRightSideBar() {
      if (!this.activeRightSideBar) return false;
      if (this.isNamedShareLoggedIn && this.$route.name === 'card-share')
        return true;

      return (
        this.currentUser &&
        !this.isRootAdministrator &&
        !this.$route.name.endsWith('-share')
      );
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setCurrentRoute'])
  },
  created() {
    if (features.isMobile.iOS()) {
      document
        .getElementById('meta-viewport')
        .setAttribute(
          'content',
          'width=device-width,user-scalable=no,initial-scale=1.0001,maximum-scale=1.0001,viewport-fit=cove'
        );
    } else {
      document
        .getElementById('meta-viewport')
        .setAttribute(
          'content',
          'width=device-width,initial-scale=1.0001,viewport-fit=cove'
        );
    }
  },
  mounted() {
    this.setCurrentRoute(this.$route);
  },
  watch: {
    $route() {
      this.setCurrentRoute(this.$route);
    }
  }
};
</script>

<style lang="scss" scoped>
.app-main {
  @include flexy($dir: row);
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;

  > :deep(.app-content) {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }
}
</style>
