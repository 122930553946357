import amplitude from 'amplitude-js';

let amplitudeIsInitialized = false;
let lastIdentityId = null;

export const identifyUser = async (identifyId, properties) => {
  if (lastIdentityId === identifyId) return;
  lastIdentityId = identifyId;

  try {
    if (window.userGuiding) {
      if (identifyId) {
        window.userGuiding.identify(identifyId, properties);
      } else {
        window.userGuiding.identify(null);
      }
    } else {
      console.error('identifyUser: user guiding not found');
    }
  } catch (e) {
    console.error('identifyApps: user guiding', e);
  }

  if (!process.env.ACTIVATE_TRACKING_APP) return;

  return new Promise((resolve, reject) => {
    try {
      if (!amplitudeIsInitialized) {
        amplitude.getInstance().init(process.env.AMPLITUDE_APP_KEY);
        amplitudeIsInitialized = true;
      }

      if (identifyId) {
        amplitude.getInstance().setUserId(identifyId);
        amplitude.getInstance().setUserProperties(properties);
      } else {
        amplitude.getInstance().clearUserProperties();
        amplitude.getInstance().setUserId(null);
      }

      resolve(true);
    } catch (e) {
      console.error('identifyApps: Amplitude', e);
      reject(e);
    }
  });
};

export const track = (event, data) => {
  if (!process.env.ACTIVATE_TRACKING_APP) return;

  const promesses = [
    new Promise((resolve) => {
      try {
        if (data) amplitude.getInstance().logEvent(event, data);
        else amplitude.getInstance().logEvent(event);
      } catch (e) {
        console.error('Error on tracking', e);
      }

      resolve();
    })
  ];

  return Promise.all(promesses);
};
