<template>
  <div class="comment-indicator" :class="$attrs.class">
    <div
      class="comment-indicator__pill"
      :class="`comment-indicator__pill--${stateComments}`"
      @click.prevent="displayComments"
    >
      <MessageIcon :imageStyle="imageStyle" />
    </div>
  </div>
</template>

<script>
import { SHARE_ASSET_TYPE } from '@/utils/types';
import authMixin from '../mixins/auth';
import commentMixin from '../mixins/comment';
import MessageIcon from '@/components/icons/Message';

export default {
  inheritAttrs: false,
  name: 'CommentIndicator',
  mixins: [commentMixin, authMixin],
  components: {
    MessageIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    raiseEventOnClick: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageStyle() {
      return {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%'
      };
    },
    stateComments() {
      if (this.item.hasUnreadComments === true) return 'has-unread';
      else if (this.item.hasUnreadComments === false) return 'all-read';
      else return 'empty';
    }
  },
  methods: {
    displayComments($event) {
      if (this.raiseEventOnClick) {
        this.$emit('click', $event);
        return;
      }

      if (this.isNamedShareLoggedIn && this.type === SHARE_ASSET_TYPE)
        this.showComments({ ...this.shareLogin }, SHARE_ASSET_TYPE);
      else if (this.type === SHARE_ASSET_TYPE)
        this.showComments(this.item, SHARE_ASSET_TYPE);
      else this.showComments(this.item);
    }
  }
};
</script>

<style lang="scss">
.comment-indicator {
  cursor: pointer;

  &__pill {
    height: 25px;
    min-width: 25px;

    &--has-unread {
      > svg {
        fill: $comment-indicator-unread-background;
      }
    }

    &--all-read {
      > svg {
        fill: $comment-indicator-background;
      }
    }

    &--empty {
      > svg {
        fill: white;
        stroke: black;
        stroke-width: 0.4;
      }
    }
  }
}
</style>
