<template>
  <section class="navigation" :class="$attrs.class">
    <NavigationItem
      :label="$t('navigation.dashboard')"
      :active="selectedItem === 'dashboard'"
      hasContent
      @selected="toggleSelectedItem('dashboard')"
    >
      <template #icon>
        <DashboardIcon size="xsmall2" />
      </template>
      <template #right>
        <div
          class="navigation-item__refresh"
          @click.prevent.stop="loadTreeviewItems"
        >
          <RefreshIcon size="small" />
        </div>
      </template>
      <DashboardTreeview @navigate="toggle" />
    </NavigationItem>

    <NavigationItem
      v-if="isParticipant"
      :label="$t('shareAsset.title')"
      hasContent
      opened
      @selected="toggleSelectedItem('share-asset')"
    >
      <template #icon>
        <ShareIcon size="xsmall2" />
      </template>
      <div class="navigation__share-asset">
        <div @click.stop="displayShareAssets()">
          {{ $t('shareAsset.search') }}
        </div>
        <div @click.stop="displayShareAssets(true)">
          {{ $t('shareAsset.displayMySharesUnreadMessages') }}
        </div>
      </div>
    </NavigationItem>

    <NavigationItem v-if="false" @selected="displayChat">
      <template #icon>
        <MessageIcon size="xsmall2" />
      </template>

      <template #label-text>
        <div class="navigation__chat">
          <div>{{ $t('navigation.chat') }}</div>
          <div
            v-if="unreadChatMessageCount"
            class="navigation__chat--unread-count"
          >
            {{ unreadChatMessageCount }}
          </div>
        </div>
      </template>
    </NavigationItem>

    <NavigationItem
      v-if="displayAdministrationMenu"
      :label="$t('navigation.administration')"
      :active="selectedItem === 'administration'"
      hasContent
      @selected="toggleSelectedItem('administration')"
    >
      <template #icon>
        <GearIcon size="xsmall2" />
      </template>
      <div class="navigation__administration">
        <div
          v-if="isAccountAdministrator"
          :class="{ selected: $route.name === 'company-parameters' }"
          @click.stop="displayCompanySettings"
        >
          {{ $t('company.settings') }}
        </div>
        <div
          v-if="isAdministrator"
          :class="{ selected: $route.name === 'account-management' }"
          @click.stop="goToAccounts"
        >
          {{ $t('account.management') }}
        </div>
        <div
          v-if="isAdministrator"
          :class="{
            selected: $route.name === 'groups' || $route.name === 'group'
          }"
          @click.stop="goGroups"
        >
          {{ $t('group.management') }}
        </div>
        <div
          v-if="canManageCompanies"
          :class="{
            selected:
              $route.name === 'company' || $route.name === 'company-accounts'
          }"
          @click.stop="goCompanies"
        >
          {{ $t('company.management') }}
        </div>
        <div v-if="canManageCompanies" @click.stop="displayAuditLogin">
          {{ $t('auditLoginPage.title') }}
        </div>
      </div>
    </NavigationItem>

    <NavigationItem :label="$t('navigation.bin')" @selected="displayBin">
      <template #icon>
        <TrashIcon size="xsmall2" />
      </template>
    </NavigationItem>

    <CompanySettings
      v-if="activeDialogue === 'company-settings'"
      @success="toggleDialogue()"
      @cancel="toggleDialogue()"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import { useNavigationStore } from '@/stores/navigation';
import NavigationItem from './NavigationItem';
import RefreshIcon from '../icons/Refresh';
import DashboardIcon from '../icons/Dashboard';
import GearIcon from '../icons/Gear';
import ShareIcon from '@/components/icons/Share';
import MessageIcon from '../icons/Message';
import TrashIcon from '../icons/Trash';
import DashboardTreeview from './DashboardTreeview';
import CompanySettings from '../company/CompanySettings';
import authMixins from '../mixins/auth';
import selectionClipboardMixin from '../mixins/selectionClipboard';
import routerMixins from '../mixins/router';
import toggleState from '../mixins/toggleState';
import {
  SEARCH_TYPE,
  COMPANY_TYPE,
  BOARD_TYPE,
  AUDIT_LOGIN_TYPE,
  CHAT_TYPE,
  SHARE_ASSET_TYPE
} from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'NavigationView',
  mixins: [
    authMixins,
    selectionClipboardMixin,
    routerMixins,
    toggleState('dialogue')
  ],
  components: {
    NavigationItem,
    RefreshIcon,
    DashboardIcon,
    GearIcon,
    ShareIcon,
    MessageIcon,
    TrashIcon,
    DashboardTreeview,
    CompanySettings
  },
  computed: {
    ...mapState(useAuthStore, ['canManageCompanies', 'isParticipant']),
    ...mapState(useNavigationStore, ['selectedItem']),
    displayAdministrationMenu() {
      return (
        !this.isSelectionMode &&
        !this.isWebShareLoggedIn &&
        (this.isAdministrator || this.canManageCompanies)
      );
    },
    isSandBox() {
      return (
        this.$route.name === BOARD_TYPE &&
        this.$route.params.id === this.currentUser.sandBoxId
      );
    },
    unreadChatMessageCount() {
      if (!this.currentUser) return '';

      const count = this.currentUser.unreadChatMessageCount;
      if (!count || count < 0) return '';
      return count < 100 ? count : '99+';
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError', 'setActiveLeftSideBar']),
    ...mapActions(useNavigationStore, ['setSelectedItem', 'loadTreeviewItems']),
    toggle() {
      this.$emit('toggle');
    },
    toggleSelectedItem(item) {
      if (this.selectedItem !== item) {
        this.setSelectedItem(item);
      } else {
        this.setSelectedItem();
      }
    },
    goSandBox() {
      this.navigate({
        name: BOARD_TYPE,
        params: { id: this.currentUser.sandBoxId }
      });

      this.$emit('toggle');
    },
    displayCompanySettings() {
      this.toggleDialogue('company-settings');
    },
    goToAccounts() {
      this.goto('accounts');
      this.$emit('toggle');
    },
    goGroups() {
      this.goto('groups');
      this.$emit('toggle');
    },
    goCompanies() {
      this.navigate({ name: COMPANY_TYPE });
      this.$emit('toggle');
    },
    displayShareAssets(displayMySharesUnreadMessages) {
      this.setSelectedItem(SHARE_ASSET_TYPE);
      this.setActiveLeftSideBar({
        type: SHARE_ASSET_TYPE,
        displayMySharesUnreadMessages
      });
    },
    displayChat() {
      this.setSelectedItem(CHAT_TYPE);
      this.setActiveLeftSideBar({
        type: CHAT_TYPE
      });
    },
    displayBin() {
      this.setSelectedItem('bin');
      this.setActiveLeftSideBar({
        type: SEARCH_TYPE,
        mode: 'bin'
      });
    },
    displayAuditLogin() {
      this.setSelectedItem(AUDIT_LOGIN_TYPE);
      this.setActiveLeftSideBar({
        type: AUDIT_LOGIN_TYPE
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation {
  @include flexy($dir: column);
  width: 100%;
  color: $font-title-list-color;
  background-color: $background-light;
  overflow-x: auto;

  > * {
    border-bottom: 1px solid $border-navigation;
  }

  > :first-child {
    border-top: 1px solid $border-navigation;
  }

  .icon-message-icon {
    fill: transparent;
    stroke: $font-dark;
  }

  &__share-asset {
    padding-bottom: $spacing-base;

    > * {
      @include flexy($align: center);
      height: $height-item;
      margin: 0 $spacing-half;
      padding-left: $spacing-base + $spacing-half;
      cursor: pointer;
    }
  }

  &__chat {
    @include flexy($align: center);

    &--unread-count {
      @include flexy($just: center, $align: center);
      border-radius: 15px;
      height: 15px;
      min-width: 15px;
      background-color: $counter-background;
      color: $font-light;
      font-size: 11px;
      font-weight: bold;
      padding: 0 calc($spacing-quarter / 2);
      margin-left: $spacing-half;
    }
  }

  &__administration {
    padding-bottom: $spacing-base;

    > * {
      @include flexy($align: center);
      height: $height-item;
      margin: 0 $spacing-half;
      padding-left: $spacing-base + $spacing-half;
      cursor: pointer;
    }

    > .selected {
      background-color: $selected-sub-navigation-color;
    }
  }
}

.navigation-item__refresh {
  cursor: pointer;
}
</style>
