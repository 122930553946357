import { mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import {
  isUserAccountAdministrator,
  isUserAdministrator,
  isUserParticipant,
  isUserParticipantRestricted
} from '../../utils/role';
import routerMixins from './router';

export default {
  mixins: [routerMixins],
  computed: {
    ...mapState(useAuthStore, [
      'currentUser',
      'isLoggedIn',
      'shareLogin',
      'isLoggedIn',
      'isNamedShareLoggedIn',
      'isWebShareLoggedIn',
      'isInPreviewWebShare',
      'isRootAdministrator',
      'isAccountAdministrator',
      'isAdministrator',
      'isParticipant',
      'isParticipantRestricted',
      'displayMode',
      'defaultLogoPath',
      'logoPath',
      'features'
    ]),
    display() {
      return this.displayMode;
    },
    currentCompany() {
      if (!this.currentUser) return {};
      return this.currentUser.company;
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['setDisplayMode', 'clearShareLogin']),
    userIsAccountAdministrator(user) {
      return isUserAccountAdministrator(user);
    },
    userIsAdministrator(user) {
      return isUserAdministrator(user);
    },
    userIsParticipant(user) {
      return isUserParticipant(user);
    },
    userIsParticipantRestricted(user) {
      return isUserParticipantRestricted(user);
    },
    setDisplay(value) {
      this.setDisplayMode(value);
    },
    goto(url) {
      this.navigate(`/${url}`);
    }
  }
};
