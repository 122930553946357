import { mapActions } from 'pinia';
import { mapStores } from '@/stores';
import { useAppStore } from '@/stores/app';
import authMixins from './auth';
import assetMixin from './asset';

import {
  BOARD_TYPE,
  FOLDER_TYPE,
  CARD_TYPE,
  MEDIA_TYPE
} from '../../utils/types';

export function toggleHiddenMixin(type) {
  const useStore = mapStores.get(type).use;

  return {
    mixins: [authMixins, assetMixin],
    computed: {
      prefixMessage() {
        const { type, brief } = this.item;
        if (type === BOARD_TYPE || type === FOLDER_TYPE)
          return `${type}Options.`;
        else if (type === CARD_TYPE)
          return `${type}Options${brief ? '.brief' : ''}.`;
        else return `${type}Options.`;
      },
      canToggleHidden() {
        if (this.isDeleted || this.item?.shareAssetId) return false;

        const { type } = this.item;

        const base = () => {
          if (this.isReadOnly || this.fromSearch) return false;
          if (!this.isProtected) return true;
          return this.isAuthorBoard;
        };

        if (type === BOARD_TYPE) {
          return (
            !this.isSandBox &&
            this.isAdministrator &&
            this.isAuthorBoard &&
            !this.fromSearch
          );
        } else if (type === FOLDER_TYPE) {
          return base();
        } else if (type === CARD_TYPE) {
          if (this.item.brief) return false;
          return base();
        } else if (type === MEDIA_TYPE) {
          return base();
        }

        return false;
      },
      toggleHiddenTitle() {
        return this.item.hidden
          ? this.$t(`${this.prefixMessage}display`)
          : this.$t(`${this.prefixMessage}hide`);
      }
    },
    methods: {
      ...mapActions(useAppStore, ['displayInfo', 'displayError']),
      ...mapActions(useStore, ['toggleHidden']),
      async toggleHiddenItem() {
        try {
          await this.toggleHidden(this.item);
          this.$emit('cancel');
        } catch (e) {
          console.error(
            `assetOptionsToggleHidden: ${this.item.type} => toggleHiddenItem`,
            e
          );
          this.displayError({ message: this.$t('global.error') });
        }
      }
    }
  };
}
