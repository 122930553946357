<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11.652 10.831"
  >
    <title v-if="text">{{ text }}</title>
    <path
      d="M6.534,1.288a6.621,6.621,0,0,0-1.208-.11C2.384,1.178,0,3.086,0,5.439S2.384,9.7,5.326,9.7s5.326-1.908,5.326-4.261A3.775,3.775,0,0,0,9.415,2.709L10.261,0A12.234,12.234,0,0,0,6.534,1.288Z"
      transform="translate(11.152 10.2) rotate(180)"
    />
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'MessageIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
