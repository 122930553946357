import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useMediaStore } from '@/stores/media';
import { diff } from '../../utils/object';

export default {
  props: {
    parentId: {
      type: Number,
      default: null
    },
    editItem: {
      type: Object,
      default: () => ({})
    },
    file: {
      type: File,
      default: null
    },
    shareAssetId: {
      type: Number,
      default: null
    },
    shareAssetLocation: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState(useAppStore, ['upload']),
    mode() {
      return this.editItem.id ? 'edit' : 'add';
    },
    uploadInProgress() {
      return !!this.upload;
    }
  },
  methods: {
    ...mapActions(useMediaStore, {
      mediaModify: 'modify'
    }),
    async modify() {
      const data = {
        ...diff(this.editItem, this.form),
        parentId: this.editItem.id ? undefined : this.parentId,
        shareAssetId: this.editItem.id ? undefined : this.shareAssetId,
        shareAssetLocation: this.editItem.id
          ? undefined
          : this.shareAssetLocation,
        id: this.editItem.id
      };

      const getData = ({ file, poster, id, ...data }) => {
        return {
          file,
          poster,
          id,
          data,
          current: this.editItem
        };
      };

      return await this.mediaModify(getData(data));
    },
    focus(name) {
      const ref = this.$refs[name];
      if (ref) ref.focus();
    },
    select(name) {
      const ref = this.$refs[name];
      if (ref) {
        ref.focus();
        ref.select();
      }
    }
  }
};
