/**
 * Generic endpoint operations.
 * Provides curry-able functions for generic API endpoint calls.
 */

import axios from 'axios';
import {
  HttpError,
  del as httpDel,
  get as httpGet,
  post as httpPost,
  put as httpPut
} from './requests';

const UPLOAD_HEADERS = ['Cache-Control'];
export const MULTI_UPLOAD_FILE_CHUNK_SIZE = 1024 * 1024 * 5; // 5MB

export const create = (endpoint) => async (data) => {
  const response = await httpPost(endpoint, data);
  return response.json();
};

export const update = (endpoint) => async (id, data) => {
  const response = await httpPut(`${endpoint}/${id}`, data);
  return response.json();
};

export const moveToBin = (endpoint) => async (id) => {
  const response = await httpPost(`${endpoint}/${id}/move-to-bin`);
  return response.json();
};

export const restoreFromBin = (endpoint) => async (id) => {
  const response = await httpPost(`${endpoint}/${id}/restore-from-bin`);
  return response.json();
};

export const del = (endpoint) => async (id) => {
  const response = await httpDel(`${endpoint}/${id}`);
  return response.json();
};

export const get =
  (endpoint) =>
  async (id, markAsReadNotification = false, share = false) => {
    const param = markAsReadNotification ? 'true' : 'false';

    let url;
    if (share) {
      url = `${endpoint}/${id}/share`;
    } else {
      url = `${endpoint}/${id}/?markAsReadNotification=${param}`;
    }

    const response = await httpGet(url);
    return response.json();
  };

export const getAssets =
  (endpoint) =>
  async (id, options = {}, share = false) => {
    const response = await httpPost(
      `${endpoint}/${id}/assets${share ? '/share' : ''}`,
      options
    );
    return response.json();
  };

export const getAll =
  (endpoint) =>
  async (options = {}) => {
    const response = await httpPost(`${endpoint}/getAll`, options);
    return response.json();
  };

export const validateCopy = (endpoint) => async (id, data) => {
  const response = await httpPost(`${endpoint}/${id}/validate-copy`, data);
  return response.json();
};

export const copy = (endpoint) => async (id, data) => {
  const response = await httpPost(`${endpoint}/${id}/copy`, data);
  return response.json();
};

export const move = (endpoint) => async (id, data) => {
  const response = await httpPost(`${endpoint}/${id}/move`, data);
  return response.json();
};

export const getSubscription = (endpoint) => async (parentId) => {
  const response = await httpGet(`${endpoint}/${parentId}/subscriptions`);
  return response.json();
};

export const addSubscription = (endpoint) => async (parentId, data) => {
  const response = await httpPost(
    `${endpoint}/${parentId}/subscriptions`,
    data
  );
  return response.json();
};

export const editSubscription = (endpoint) => async (parentId, id, data) => {
  const response = await httpPut(
    `${endpoint}/${parentId}/subscriptions/${id}`,
    data
  );
  return response.json();
};

export const updatePositionSubElements = (endpoint) => async (id, data) => {
  const response = await httpPost(
    `${endpoint}/${id}/update-position-sub-elements`,
    data
  );
  return response.json();
};

export const setAssetTagSubElements = (endpoint) => async (id, data) => {
  const response = await httpPost(
    `${endpoint}/${id}/set-asset-tag-sub-elements`,
    data
  );
  return response.json();
};

export const uploadFile = async (response, file) => {
  if (!response.ok) {
    throw new HttpError(response.status, await response.text(), response);
  }

  const { url } = await response.json();
  // Some params from the upload URL must be replicated in the PUT request itself
  const { searchParams } = new URL(url);

  const headers = UPLOAD_HEADERS.reduce((prev, header) => {
    if (searchParams.has(header)) {
      prev[header] = searchParams.get(header);
    }
    return prev;
  }, {});

  // Put the file there
  response = await fetch(url, {
    method: 'PUT',
    headers,
    body: file
  });

  if (!response.ok) {
    throw new HttpError(response.status, await response.text(), response);
  }
};

export const multiPartUploadFile = async (options) => {
  if (!options) throw new Error('The "options" parameter cannot be null!');

  const {
    id,
    endpoint,
    multipartInfo,
    file,
    onProgress,
    onRetry,
    onComplete,
    onAbort,
    setCancelTokenSource,
    maxUploadTries = 3,
    abortUploadPartUrl = 'abort-upload',
    completeUploadPartUrl = 'complete-upload'
  } = options;

  if (!endpoint) throw new Error('The "endpoint" parameter cannot be null!');
  if (!multipartInfo)
    throw new Error('The "multipartInfo" parameter cannot be null!');
  if (!file) throw new Error('The "file" parameter cannot be null!');

  const { bucket, key, uploadId, urls } = await multipartInfo;
  let aborted = false;

  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (setCancelTokenSource) setCancelTokenSource(source);

    const progressChunks = [];
    const fileSize = file.size;
    const NUM_CHUNKS = Math.floor(fileSize / MULTI_UPLOAD_FILE_CHUNK_SIZE) + 1;

    const refreshProgress = () => {
      if (!onProgress) return;

      let loaded = 0;
      for (let i = 0; i < progressChunks.length; i++)
        loaded += progressChunks[i];
      onProgress(loaded);
    };

    const uploadChunk = async (index, blob, tryNum = 1) => {
      try {
        progressChunks[index - 1] = 0;
        refreshProgress();

        return await axios.put(urls[`${index}`], blob, {
          onUploadProgress: (event) => {
            progressChunks[index - 1] = event.loaded;
            refreshProgress();
          },
          cancelToken: source.token,
          headers: { 'Content-Type': file.type }
        });
      } catch (e) {
        if (axios.isCancel(e)) {
          aborted = true;
          throw new Error(`Failed uploading part: #${index}`);
        } else if (tryNum <= maxUploadTries) {
          if (onRetry) onRetry(index, tryNum, maxUploadTries);
          return uploadChunk(index, blob, tryNum + 1);
        } else {
          throw new Error(`Failed uploading part: #${index}`);
        }
      }
    };

    const promises = [];

    for (let index = 1; index < NUM_CHUNKS + 1; index++) {
      const start = (index - 1) * MULTI_UPLOAD_FILE_CHUNK_SIZE;
      const end = index * MULTI_UPLOAD_FILE_CHUNK_SIZE;
      const blob =
        index < NUM_CHUNKS ? file.slice(start, end) : file.slice(start);
      progressChunks.push(0);
      promises.push(uploadChunk(index, blob));
    }

    const responses = await Promise.all(promises);

    if (onComplete) onComplete();

    const uploadParts = [];
    responses.forEach((response, index) => {
      uploadParts.push({
        ETag: response.headers.etag,
        PartNumber: index + 1
      });
    });

    await httpPost(`${endpoint}/${completeUploadPartUrl}`, {
      id,
      bucket,
      key,
      parts: uploadParts,
      uploadId
    });
  } catch (e) {
    try {
      if (!aborted) {
        console.error('Error uploading file', e);
      }

      await httpPost(`${endpoint}/${abortUploadPartUrl}`, {
        id,
        bucket,
        key,
        uploadId
      });
    } catch (e) {
      console.error('Error aborting upload', e);
    }

    if (onAbort) onAbort();
  }
};
