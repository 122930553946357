<template>
  <MediaFormDialogue
    class="media-background-dialogue"
    :class="[`media-background-dialogue--${mediaType}`, $attrs.class]"
    :title="$t('mediaForm.background.label')"
    @cancel="$emit('cancel')"
    @ok="ok()"
    v-model:error="error"
  >
    <div class="media-background-dialogue__content" @keydown.enter.prevent="ok">
      <BaseInput
        ref="name"
        :label="$t('mediaForm.description')"
        name="name"
        :maxlength="120"
        v-model.trim="mediaName"
        isReadOnly
      />

      <div class="media-background-dialogue__content__config">
        <div class="media-background-dialogue__content__config--bg">
          <div class="media-background-dialogue__content__config--bg--label">
            <span>{{ $t('mediaForm.background.addBackgroundImage') }}</span>
          </div>

          <div class="media-background-dialogue__content__config--bg--content">
            <FileInput
              v-if="displayTakePicture"
              class="media-background-dialogue__content__config--bg--item"
              :accept="['image/*']"
              :cta="false"
              capture="environment"
              name="capture"
              size="auto"
              @change="fileSelected"
            >
              <div
                class="media-background-dialogue__content__config--bg--icon"
                :title="$t('mediaForm.background.takePicture')"
              >
                <PhotoIcon />
              </div>
            </FileInput>

            <FileInput
              v-if="displaySelectImage"
              class="media-background-dialogue__content__config--bg--item"
              :accept="acceptedTypes"
              :cta="false"
              name="files"
              size="auto"
              @change="fileSelected"
            >
              <div
                class="media-background-dialogue__content__config--bg--icon"
                :title="$t('mediaForm.background.selectImage')"
              >
                <ImageIcon />
              </div>
            </FileInput>

            <div
              class="media-background-dialogue__content__config--bg--item"
              v-if="preview"
            >
              <div
                :class="[
                  'media-background-dialogue__content__config--bg--icon',
                  'media-background-dialogue__content__config--bg--preview'
                ]"
                :style="tileBackgroundStyle"
              ></div>
            </div>

            <div
              class="media-background-dialogue__content__config--bg--item"
              v-if="displayDeleteBackground"
            >
              <div
                class="media-background-dialogue__content__config--bg--icon"
                @click="deleteTileBackground"
              >
                <TrashIcon />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="media-background-dialogue__content__result">
        <div class="media-background-dialogue__content__result--label">
          <span>{{ $t('mediaForm.background.result') }}</span>
        </div>
        <div
          class="media-background-dialogue__content__result__tile"
          :class="`media-background-dialogue__content__result__tile--${mediaType}`"
          :style="tileBackgroundStyle"
        >
          <AudioControl
            v-if="mediaType === 'audio'"
            :item="editItem"
            disabled
          />
          <div
            v-else-if="mediaType === 'video'"
            class="media-background-dialogue__content__result__tile__inner--video"
          >
            <VideoMediaIcon size="large2" />
          </div>
          <div
            v-else-if="mediaType === 'file'"
            class="media-background-dialogue__content__result__tile__inner--file"
          >
            <div
              class="media-background-dialogue__content__result__tile__inner--file--pill"
            >
              <div v-if="fileExt">{{ fileExt }}</div>
              <FileMediaIcon v-else size="medium" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </MediaFormDialogue>
</template>

<script>
import features from '../../utils/features';
import { toMegabytes } from '../../utils/numbers';
import MediaFormDialogue from './MediaFormDialogue';
import FileInput from '../forms/FileInput';
import PhotoIcon from '../icons/PhotoMedia';
import ImageIcon from '../icons/Image';
import TrashIcon from '../icons/Trash';
import AudioControl from '../controls/AudioControl';
import VideoMediaIcon from '../icons/VideoMedia';
import FileMediaIcon from '../icons/FileMedia';
import mediaForm from '../mixins/mediaForm';

const acceptedTypes = process.env.PHOTO_MEDIA_SUPPORTED_TYPES;
const maxSize = process.env.MEDIA_FILE_MAX_SIZE;

export default {
  inheritAttrs: false,
  name: 'FormDialogueLink',
  mixins: [mediaForm],
  components: {
    MediaFormDialogue,
    FileInput,
    PhotoIcon,
    ImageIcon,
    TrashIcon,
    AudioControl,
    VideoMediaIcon,
    FileMediaIcon
  },
  data() {
    const { name, poster } = this.editItem;

    return {
      acceptedTypes,
      form: {
        poster: null
      },
      mediaName: name,
      preview: poster?.url || '',
      submitted: false,
      error: ''
    };
  },
  computed: {
    mediaType() {
      return this.editItem?.mediaType || '';
    },
    canCapture() {
      return features.captureAttribute();
    },
    posterFileExt() {
      const reg = /.+\.(.+)$/i;

      const fileName =
        this.form.poster?.name || this.editItem.poster?.name || '';
      const match = fileName.trim().match(reg);
      if (match && match[1]) {
        return match[1].trim().toLowerCase();
      }

      return '';
    },
    fileExt() {
      const reg = /.+\.(.+)$/i;

      const fileName = this.editItem.fileInfos?.name || '';
      const match = fileName.trim().match(reg);
      if (match && match[1]) {
        return match[1].trim().toLowerCase();
      }

      return '';
    },
    hasPreview() {
      const ext = this.posterFileExt;
      return !(ext === 'heic' || ext === 'heif' || ext === '');
    },
    displayTakePicture() {
      return this.canCapture && !this.tileBackgroundStyle;
    },
    displaySelectImage() {
      return !this.tileBackgroundStyle;
    },
    displayDeleteBackground() {
      return !!this.tileBackgroundStyle;
    },
    tileBackgroundStyle() {
      if (!this.preview) return null;

      return {
        backgroundColor: '#fff',
        backgroundImage: `url(${this.preview})`,
        border: 'none'
      };
    }
  },
  methods: {
    async ok() {
      if (this.submitted) return;
      this.submitted = true;

      try {
        if (this.editItem.poster?.id && !this.preview)
          this.form.mediaPosterId = null;
        else delete this.form.mediaPosterId;

        const media = await this.modify();
        this.$emit('success', media);
      } catch (e) {
        console.error(e);
        this.error = this.$t('mediaForm.error');
      } finally {
        this.submitted = false;
      }
    },
    clearPreview() {
      if (!this.preview) return;

      URL.revokeObjectURL(this.preview);
      this.preview = '';
    },
    deleteTileBackground() {
      this.clearPreview();

      this.form.poster = null;
    },
    fileSelected(files) {
      // eslint-disable-next-line
        const [file, ..._rest] = files

      if (file.size > maxSize) {
        this.error = this.$t('mediaForm.sizeError', {
          size: this.$n(toMegabytes(file.size), 'decimal'),
          max: this.$n(toMegabytes(maxSize), 'decimal')
        });
        return;
      }

      this.form.poster = file;

      this.preview = URL.createObjectURL(file);
    }
  }
};
</script>

<style lang="scss">
$element-height: 40px;
$bg-icon-color: #048b9d;
$border-color: rgba(112, 112, 112, 1); // #707070
$larger-width: 700px;

.media-background-dialogue {
  @include media-dialogue(background-color);

  > .dialogue__box {
    @media screen and (max-width: #{$larger-width - 1}) {
      width: 320px;
      max-width: 100%;
      max-height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (min-width: $larger-width) {
      width: 800px;
      max-width: 800px;
      max-height: 100%;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dialogue__main {
    overflow: hidden;
  }

  .dialogue__main--content {
    border: solid 1px $background-light;
  }

  .dialogue__header {
    font-style: $font-italic;
  }

  .form-field {
    border: none;
    margin: 0;
    margin-bottom: $spacing-base;
  }

  &__content {
    @include flexy($dir: column, $just: center);
    margin: 30px;

    &__config {
      @include flexy($dir: column);

      &--bg {
        @include flexy($dir: column);

        &--label {
          margin-bottom: $spacing-third;
        }

        &--content {
          @include flexy();
        }

        &--item {
          padding: 0;
          margin-right: 10px;
        }

        &--item:last-child {
          margin-right: 0;
        }

        &--icon {
          @include flexy($align: center, $just: center);
          width: $element-height;
          height: $element-height;
          border: solid 1px $border-color;
          border-radius: $base-border-radius;
          background-color: $bg-icon-color;
          cursor: pointer;

          > * {
            height: 20px;
            min-height: 20px;
            width: 20px;
            min-width: 20px;
            fill: $font-light;
          }
        }

        &--preview {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: unset;
          cursor: default;
        }
      }
    }

    &__result {
      @include flexy($dir: column, $align: center);
      margin-top: $spacing-base;

      &--label {
        margin-bottom: $spacing-third;
      }

      &__tile {
        @include flexy($align: center, $just: center);

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        width: 400px;
        height: 400px;
        border-radius: $tile-media-border-radius;
        cursor: pointer;

        &--audio {
          background-color: $media-audio;
          border: 1px solid $media-audio;

          > * {
            transform: scale(0.9);
          }
        }

        &--video {
          background-color: $media-video;
          border: 1px solid $media-video;
        }

        &--file {
          background-color: $media-file;
          border: 1px solid $media-file;
        }

        &__inner {
          &--video {
            > svg {
              fill: rgba(255, 255, 255, 1);
            }
          }

          &--file {
            @include flexy($align: center, $just: center);

            &--pill {
              @include flexy($align: center, $just: center);
              width: 53px;
              height: 53px;
              border-radius: 50% 50%;
              background-color: #ffffff;

              > div {
                color: $media-file;
                font-weight: bold;
                text-transform: uppercase;
              }

              > svg {
                fill: $media-file;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: #{$larger-width - 1}) {
      margin: 15px;

      &__result {
        &__tile {
          width: 290px;
          height: 290px;

          &--audio {
            > * {
              transform: scale(0.8);
            }
          }
        }
      }
    }
  }
}
</style>
