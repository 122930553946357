<template>
  <div
    class="dashboard-treeview-item"
    :class="[
      {
        'dashboard-treeview-item--selected':
          $route.name === item.type && $route.params.id === item.id
      },
      $attrs.class
    ]"
  >
    <div
      class="dashboard-treeview-item__left"
      @click.stop.prevent="$emit('toggle')"
    >
      <div
        class="dashboard-treeview-item__spacer"
        v-for="i in level"
        :key="`${item.id}-${i}`"
      ></div>
      <div
        class="dashboard-treeview-item__toggle"
        :class="{ 'dashboard-treeview-item__toggle--expanded': expanded }"
      >
        <Chevron size="xsmall" direction="right" />
      </div>
    </div>

    <div class="dashboard-treeview-item__right" @click.stop.prevent="select">
      <div
        class="dashboard-treeview-item__type"
        :class="`dashboard-treeview-item__type--${item.type}`"
      >
        <FolderIcon size="xsmall" />
      </div>
      <div class="dashboard-treeview-item__name">{{ displayName }}</div>
    </div>
  </div>
</template>

<script>
import Chevron from '../icons/Chevron';
import FolderIcon from '../icons/Folder';
import routerMixins from '../mixins/router';

export default {
  inheritAttrs: false,
  name: 'dashboardTreeviewItem',
  mixins: [routerMixins],
  components: {
    Chevron,
    FolderIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 0
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayName() {
      if (this.item.sandBox && this.item.name === 'SANDBOX') {
        return this.$t('navigation.privateWorkspace');
      } else {
        return this.item.name;
      }
    }
  },
  methods: {
    select() {
      this.navigate({
        name: this.item.type,
        params: { id: this.item.id }
      });

      this.$emit('navigate');
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard-treeview-item {
  @include grid($cols: auto 1fr, $areas: 'left right');
  height: $height-item;
  padding: 0 $spacing-half;

  &__left,
  &__right {
    @include flexy($align: center);
    white-space: nowrap;
    cursor: pointer;
  }

  &__left {
    @include grid-item($area: left);
  }

  &__right {
    @include grid-item($area: right);
  }

  &__spacer {
    width: $spacing-base;
    flex: 0 0 auto;
  }

  &__toggle {
    @include flexy($align: center, $just: center);
    flex: 0 0 auto;
    width: 30px;
    height: 100%;

    > .icon-chevron-icon {
      @include ease();
    }

    &--expanded {
      > .icon-chevron-icon {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }

  &__type {
    @include flexy($align: center, $just: center);
    height: 100%;

    &--board {
      > * {
        fill: $board-icon-color;
      }
    }

    &--folder {
      > * {
        fill: $folder-icon-color;
      }
    }
  }

  &__name {
    padding: 0 $spacing-half;
    white-space: nowrap;
    flex: 1;
  }

  &--selected {
    > * {
      background-color: $selected-sub-navigation-color;
    }
  }
}
</style>
