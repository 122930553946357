import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';

import {
  getAll as apiGetAll,
  validateCopy as apiValidateCopy,
  copy as apiCopy,
  move as apiMove,
  getHiddenCount as apiGetHiddenCount
} from '@/api/endpoints/dashboard';
import { defaultDashboardSort, sortDashboardOptions } from '@/composables/sort';
import { DASHBOARD_TYPE, BOARD_TYPE } from '@/utils/types';

const defaultLimit = 30;

const getDefaultFilter = () => {
  return {
    keys: {},
    displayHidden: false,
    hasFilters: false
  };
};

const initialState = {
  assets: [],
  filter: getDefaultFilter(),
  sort: localStorage.getItem('dashboardSort') || defaultDashboardSort,
  hasNextPage: false,
  hiddenCount: 0,
  searchText: null
};

export const key = DASHBOARD_TYPE;
export const useDashboardStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      const { loading } = this;
      Object.assign(this, { ...initialState });
      this.loading = loading;
      this.filter = getDefaultFilter();
      this.sort = localStorage.getItem('dashboardSort') || defaultDashboardSort;
    },
    async assetExists({ id, current = false }) {
      if (current) return false;
      return this.assets.some((asset) => asset.id === id);
    },
    async getAssets(options = {}) {
      const { sort, filter, assets, searchText } = this;
      let { start = assets.length, text } = options;

      if (text === undefined) text = searchText;
      const limit =
        start === 0 && assets.length > defaultLimit
          ? assets.length
          : defaultLimit;

      const { items, hasNextPage } = await apiGetAll({
        query: { start, sort: sortDashboardOptions[sort], limit },
        text: text || '',
        displayHidden: !!filter.displayHidden
      });

      this.assets = start === 0 ? items : [...this.assets, ...items];
      this.hasNextPage = hasNextPage;
      this.searchText = text;

      return { items, hasNextPage };
    },
    async setFilter(newFilter) {
      const { filter } = this;

      if (filter.keys[newFilter.key]) {
        if (newFilter.key === 'displayHidden') {
          filter.displayHidden = false;
        }

        delete filter.keys[newFilter.key];
      } else {
        filter.keys[newFilter.key] = newFilter.key;

        if (newFilter.key === 'displayHidden') {
          filter.displayHidden = true;
        }
      }

      this.filter = {
        ...filter,
        hasFilters: filter.displayHidden
      };

      await this.getAssets({ start: 0 });
    },
    async setSort(sort, reload = true) {
      this.sort = sort;
      localStorage.setItem('dashboardSort', sort);
      if (reload) await this.getAssets({ start: 0 });
    },
    async search({ text }) {
      await this.getAssets({ start: 0, text });
    },
    async clearSearch() {
      await this.getAssets({ start: 0, text: '' });
    },
    async clearFilters() {
      this.filter = getDefaultFilter();
      this.setSort(defaultDashboardSort, false);
      await this.getAssets({ start: 0, text: '' });
    },
    async validateCopy({ data }) {
      return await apiValidateCopy(data);
    },
    async copy({ items }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        await apiCopy(items);
        await this.getAssets({ start: 0 });
      } finally {
        await appStore.stopLoading();
      }
    },
    async move({ items }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        await apiMove(items);
        await this.getAssets({ start: 0 });
      } finally {
        await appStore.stopLoading();
      }
    },
    async hideItem(item) {
      await this.setHiddenCount();

      const { filter } = this;
      if (filter.displayHidden) return;

      if (!this.assets.some((x) => x.id === item.id)) return;
      if (this.hasNextPage) {
        await this.getAssets({ start: 0 });
        return;
      }

      await this.assetDeleted(item);
    },
    async displayItem() {
      await this.setHiddenCount();

      const { filter } = this;
      if (filter.displayHidden) return;

      await this.getAssets({ start: 0 });
    },
    async setHiddenCount() {
      const { count } = await apiGetHiddenCount();
      this.hiddenCount = count;
    },
    async assetUpdated(item) {
      if (item.type !== BOARD_TYPE) return;
      if (!this.assets.some((asset) => asset.id === item.id)) return;
      this.assets = this.assets.map((asset) =>
        asset.id !== item.id ? asset : { ...item }
      );
    },
    async assetDeleted({ id, type }) {
      if (type !== BOARD_TYPE) return;
      if (!this.assets.some((asset) => asset.id === id)) return;
      this.assets = this.assets.filter((asset) => asset.id !== id);
    }
  }
});

export default {
  key,
  use: useDashboardStore
};
