import { get as httpGet, post as httpPost } from '../requests';
import {
  get as genericGet,
  getAll as genericGetAll,
  create as genericCreate,
  update as genericUpdate,
  uploadFile as genericUploadFile
} from '../generic';

export const ENDPOINT_URL = '/accounts';

export const me = async () => {
  const response = await httpGet(`${ENDPOINT_URL}/me`, [], {
    redirectToLoginOnUnauthorize: false
  });
  return response ? response.json() : null;
};

export const get = genericGet(ENDPOINT_URL);

export const getAll = genericGetAll(ENDPOINT_URL);

export const create = genericCreate(ENDPOINT_URL);

export const update = genericUpdate(ENDPOINT_URL);

export const updateProfile = async (data) => {
  const response = await httpPost(`${ENDPOINT_URL}/update-profile`, data);
  return response.json();
};

export const getUnreadNotificationCount = async () => {
  const response = await httpGet(`${ENDPOINT_URL}/unread-notification-count`);
  return response.json();
};

export const getUnreadChatMessageCount = async () => {
  const response = await httpGet(`${ENDPOINT_URL}/unread-chat-message-count`);
  return response.json();
};

export const getEvaluationCount = async () => {
  const response = await httpGet(`${ENDPOINT_URL}/evaluation-count`);
  return response.json();
};

export async function createUploadPicture(token, file) {
  // Get the upload URL from the backend
  const response = await httpPost(
    `${ENDPOINT_URL}/create-picture-upload-url/${token}`,
    {
      filePath: file.name,
      contentType: file.type
    }
  );

  return genericUploadFile(response, file);
}

export async function uploadPicture(file) {
  // Get the upload URL from the backend
  const response = await httpPost(`${ENDPOINT_URL}/picture-upload-url`, {
    filePath: file.name,
    contentType: file.type
  });

  return genericUploadFile(response, file);
}

export const mfaDeactivate = async (id) => {
  const response = await httpPost(`${ENDPOINT_URL}/mfa/deactivate/${id}`);
  return response.json();
};

export const getActiveCount = async (companyId) => {
  const response = await httpGet(
    `${ENDPOINT_URL}/active-count${companyId ? '/?companyId=' + companyId : ''}`
  );
  return response.json();
};
