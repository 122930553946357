import { get as httpGet, post as httpPost } from '../requests';
import {
  create as genericCreate,
  update as genericUpdate,
  del as genericDelete,
  updatePositionSubElements as genericUpdatePositionSubElements
} from '../generic';

export const ENDPOINT_URL = '/share-assets';

export const create = genericCreate(ENDPOINT_URL);
export const update = genericUpdate(ENDPOINT_URL);
export const del = genericDelete(ENDPOINT_URL);

export const get = async (id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${id}`);
  return response.json();
};

export const getShares = async (options = {}) => {
  const response = await httpPost(`${ENDPOINT_URL}/get-shares`, options);
  return response.json();
};

export const getQrCode = async (id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${id}/qrcode`);
  return response.json();
};

export const getAssets = async (id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${id}/assets`);
  return response.json();
};

export const validateHash = async (data) => {
  const response = await httpPost(`${ENDPOINT_URL}/validate-hash`, data);
  return response.json();
};

export const updatePositionSubElements =
  genericUpdatePositionSubElements(ENDPOINT_URL);
