<template>
  <div class="member-options" :class="$attrs.class">
    <button
      v-if="displayAddButton"
      class="member-options__create icon-button"
      :class="`member-options__create--${type}`"
      @click="toggleCreationMenu"
    >
      <CreationIcon type="light" />
      <GroupIcon v-if="type !== 'group'" type="light" size="mediumlarge" />
    </button>
    <button
      v-if="showDelete"
      class="member-options__delete icon-button"
      @click="toggleDeletionMenu"
    >
      <TrashIcon type="light" />
    </button>

    <!-- Creation menu -->
    <transition name="slide">
      <SlideMenu v-if="activeMenu === 'creation'" slide="up" :fullWidth="true">
        <MenuItem
          v-if="type !== 'group'"
          :label="$t('memberManagement.addNewGroup')"
          @selected="$emit('selected', 'addNewGroup')"
        />
        <MenuItem
          v-if="canInviteMultiple"
          :label="$t('memberManagement.inviteMultiple')"
          @selected="
            $emit('selected', 'inviteMultiple');
            activeMenu = '';
          "
        />
      </SlideMenu>
    </transition>

    <!-- Deletion menu -->
    <transition name="slide">
      <SlideMenu v-if="activeMenu === 'deletion'" slide="up" :fullWidth="true">
        <MenuItem
          :label="$t('memberManagement.selectToDelete')"
          @selected="$emit('selected', 'selectToDelete')"
        />
        <MenuItem
          :label="$t('memberManagement.deleteAll')"
          @selected="$emit('selected', 'deleteAll')"
        />
      </SlideMenu>
    </transition>
  </div>
</template>

<script>
import SlideMenu from '../menus/SlideMenu';
import MenuItem from '../menus/MenuItem';
import GroupIcon from '../icons/Group';
import CreationIcon from '../icons/Creation';
import TrashIcon from '../icons/Trash';
import toggleState from '../mixins/toggleState';

export default {
  inheritAttrs: false,
  name: 'MemberOptions',
  mixins: [toggleState('menu')],
  components: {
    SlideMenu,
    MenuItem,
    GroupIcon,
    CreationIcon,
    TrashIcon
  },
  props: {
    type: {
      type: String,
      required: true
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    canInviteMultiple: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    displayAddButton() {
      return this.canInviteMultiple || this.type !== 'group';
    }
  },
  methods: {
    toggleCreationMenu() {
      if (this.activeMenu === 'creation') this.toggleMenu('');
      else this.toggleMenu('creation');
    },
    toggleDeletionMenu() {
      if (this.activeMenu === 'deletion') this.toggleMenu('');
      else this.toggleMenu('deletion');
    }
  }
};
</script>

<style lang="scss">
.member-options {
  @include flexy();

  &__create {
    > .icon-group-icon {
      margin-left: 2px;
    }

    &--board > .icon-group-icon .check {
      fill: $board-base;
    }

    &--evaluation > .icon-group-icon .check,
    &--evaluationResult > .icon-group-icon .check {
      fill: $evaluation-base;
    }
  }

  &__delete {
    margin-left: $spacing-half;
  }
}
</style>
